import { ArrowCircleLeft } from 'heroicons-react';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import S3 from 'aws-sdk/clients/s3'
import { randomBytes } from 'crypto'
import ProgressiveImage from 'react-progressive-graceful-image';
import { useNavigate } from 'react-router-dom';

export default function Step2_AddProduct({ catalog, products, setProducts, hasNext, hasPrevious }) {
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    })
    const [productDetails, setProductDetails] = useState({
        name: "",
        image: [],
        image360: [],
        specification: {}
    })

    const navigate = useNavigate();
    const [productError, setProductError] = useState({})
    const [productUpdateStatus, setProductUpdateStatus] = useState(false)

    const handlepostImage = (imageList, type) => {
        if (type === "2d") {
            setProductError({
                ...productError,
                image: ""
            })
            imageUpload(imageList[0], catalog?.name + "/" + productDetails?.name + "/", (url) => {
                setProductDetails({
                    ...productDetails,
                    image: [
                        ...productDetails.image,
                        url
                    ]
                })
            })
        }
        if (type === "3d") {
            imageUpload(imageList[0], catalog?.name + "/" + productDetails?.name + "/", (url) => {
                setProductDetails({
                    ...productDetails,
                    image360: [
                        ...productDetails.image360,
                        url
                    ]
                })
            })
        }
    };

    const imageDelete = (imageFile, type) => {
        s3.deleteObject({
            Bucket: bucketName,
            Key: imageFile.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', '')
        }, function (err, data) {
            if (type === '2d') {
                setProductDetails({
                    ...productDetails,
                    image: productDetails.image.filter(image => image !== imageFile)
                })
            }
            if (type === '3d') {
                setProductDetails({
                    ...productDetails,
                    image360: productDetails.image360.filter(image360 => image360 !== imageFile)
                })
            }
            return data
        })
    }

    const imageUpload = async (imageFile, path, callback) => {
        const rawBytes = await randomBytes(16)
        const imageName = rawBytes.toString('hex')

        const uploadURL = await s3.getSignedUrlPromise('putObject', {
            Bucket: bucketName,
            Key: "Catalog/" + path + imageName + "." + imageFile?.file?.name?.split(".")[1],
            Expires: 60
        })

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: imageFile.file
        })

        const imageUrl = uploadURL.split('?')[0]
        callback(imageUrl)
    };

    const addProduct = () => {
        var error = productError
        if (productDetails?.name === "") {
            error.name = "Enter Product Design Number"
        } else {
            error.name = ""
        }

        if (productDetails?.image?.length === 0) {
            error.image = "Upload Product Image"
        } else {
            error.image = ""
        }

        setProductError({
            ...error
        })

        const errorCount = error ? Object.keys(error).filter(item => error[item] !== "").length : 0
        if (errorCount === 0) {
            setProducts([
                productDetails,
                ...products,
            ])

            setProductDetails({
                name: "",
                image: [],
                image360: [],
            })
        }
    }

    const updateProduct = () => {
        var error = productError
        if (productDetails?.name === "") {
            error.name = "Enter Product Design Number"
        } else {
            error.name = ""
        }

        if (productDetails?.image?.length === 0) {
            error.image = "Upload Product Image"
        } else {
            error.image = ""
        }

        setProductError({
            ...error
        })

        const errorCount = error ? Object.keys(error).filter(item => error[item] !== "").length : 0
        if (errorCount === 0) {
            products[productDetails.id] = {
                ...productDetails,
                name: productDetails.name,
                image: productDetails.image,
                image360: productDetails.image360,
                specification: productDetails.specification
            }
            setProducts([
                ...products,
            ])
            setProductUpdateStatus(false)
            const spec = {
                name: "",
                image: [],
                image360: [],
                specification: {}
            }
            setProductDetails(spec)
        }
    }

    const handleNext = () => {
        if (products.length >= 1) {
            hasNext(true)
        } else {
            hasNext(false)
        }
    }

    return (
        <div className='px-5'>
            <button className='flex p-2 items-center text-md lg:w-auto leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none' onClick={() => navigate("/catalog")}>
                <ArrowCircleLeft
                    className=""
                /><span className='ml-2'>Back to Catalog</span>
            </button>
            <div className="flex flex-col md:flex-row p-5">
                <div className="md:h-auto lg:w-1/2 w-full"
                    style={{ display: 'flex' }}>
                    {
                        products.length === 0 ?
                            (
                                <ProgressiveImage src="https://cdn.pixabay.com/photo/2021/01/15/17/01/green-5919790__340.jpg">
                                    {(src) => (
                                        <img
                                            className="object-cover w-full h-full"
                                            src={src}
                                            alt="img"
                                        />
                                    )}
                                </ProgressiveImage>
                            ) :
                            (
                                <div className="mt-1 w-full">
                                    <div
                                        style={{ alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <div className="w-96 max-h-96 rounded-2xl shadow-lg mb-4">
                                                <ProgressiveImage src={catalog?.image === "" ? "https://cdn.pixabay.com/photo/2021/01/15/17/01/green-5919790__340.jpg" : catalog?.image}>
                                                    {(src) => (
                                                        <img
                                                            className="object-contain rounded-2xl w-96 max-h-96"
                                                            src={src}
                                                            alt="img"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                        </div>
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <label className="text-2xl">{catalog?.name}</label>
                                        </div>
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <label>{catalog?.name}</label>
                                        </div>
                                    </div>
                                    <div className='p-4 overflow-x-auto'>
                                        <table className="table borderd min-w-full">
                                            <thead>
                                                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                                    <th className="py-3 px-6 text-left">Design Number</th>
                                                    <th className="py-3 px-6 text-left">Catalog Name</th>
                                                    <th className="py-3 px-6 text-left">Images</th>
                                                    <th className="py-3 px-6 text-left">360 Images</th>
                                                    <th className="py-3 px-6 text-left">Specifications</th>
                                                    <th className="py-3 px-6 text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                className="text-capitalize"
                                            >
                                                {
                                                    products.map((item, i) => {
                                                        return (
                                                            <tr key={item.name} className="border-b border-gray-200 hover:bg-gray-100">
                                                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        {item?.name}
                                                                    </div>
                                                                </td>
                                                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        {catalog?.name}
                                                                    </div>
                                                                </td>
                                                                <td className="py-3 px-6 text-left whitespace-nowrap">

                                                                    {item?.image?.map(imges => <div className="flex items-center">
                                                                        <img
                                                                            className="object-cover w-full h-full mt-1"
                                                                            src={imges}
                                                                            alt="img"
                                                                        />
                                                                    </div>)}

                                                                </td>
                                                                <td className="py-3 px-6 text-left whitespace-nowrap">

                                                                    {item?.image360?.map(imges => <div className="flex items-center">
                                                                        <img
                                                                            className="object-cover w-full h-full mt-1"
                                                                            src={imges}
                                                                            alt="img"
                                                                        />
                                                                    </div>)}

                                                                </td>
                                                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        {
                                                                            item?.specification ? Object.keys(item?.specification)?.map((item, index) => {
                                                                                return (
                                                                                    <span key={item}
                                                                                        className="px-2 py-1 mb-2 rounded-full text-gray-500 bg-gray-200 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                                                                                        {item}
                                                                                    </span>
                                                                                )
                                                                            }) : null
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="py-3 px-6 text-center">
                                                                    <div className="flex item-center justify-center">
                                                                        <button className="p-2 rounded-full bg-white text-green-500 font-medium text-xs leading-normal uppercase shadow-md hover:text-yellow-600  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out align-center flex hover:underline"
                                                                            onClick={() => {
                                                                                setProductUpdateStatus(true)
                                                                                setProductDetails({
                                                                                    id: i,
                                                                                    ...products[i],
                                                                                    name: item?.name,
                                                                                    image: item?.image,
                                                                                    image360: item?.image360,
                                                                                    specification: item.specification
                                                                                })
                                                                            }}>
                                                                            <svg fill="currentColor" name="edit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" width="20px" height="20px"><path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z" /></svg>
                                                                        </button>

                                                                        <button className="p-2 ml-2 rounded-full bg-white text-red-600 font-medium text-xs leading-normal uppercase shadow-md hover:text-yellow-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out align-center flex hover:underline"
                                                                            onClick={() => {
                                                                                if (item?.image)
                                                                                    item.image.map(img => imageDelete(img, "2d"))
                                                                                const prod = products.filter(prod => prod.name !== item.name)
                                                                                setProducts([
                                                                                    ...prod
                                                                                ])
                                                                            }}>
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px">    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" /></svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                    }

                </div>
                <div className="flex justify-center px-6 sm:w-full lg:w-1/2 w-full">
                    <div className="w-full">
                        <h3 className="mb-4 text-xl font-bold text-yellow-600">
                            Add Products In Catalog
                        </h3>

                        <div className="w-full bg-gray-200 rounded-full">
                            <div className={`w-2/3 p-1 text-xs font-medium leading-none text-center text-yellow-100 bg-yellow-600 rounded-full`}
                            >
                                Step 2
                            </div>
                        </div>

                        <div>
                            <div className="mt-4 mb-4">
                                <label className="block mb-2 text-sm"> Catalog Name </label>
                                <input
                                    type="text"
                                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-yellow-400 focus:outline-none focus:ring-1 focus:ring-yellow-600"
                                    placeholder="Catalog Name"
                                    value={catalog?.name} disabled="disabled" />
                            </div>
                            <div className="mt-4 mb-4">
                                <label className="block mb-2 text-sm"> Product Design Number </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="w-full px-4 py-2 text-sm border rounded-md focus:border-yellow-400 focus:outline-none focus:ring-1 focus:ring-yellow-600"
                                        placeholder=""
                                        value={productDetails?.name}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 14) {
                                                var value = productDetails?.name
                                                value = e.target.value

                                                setProductError({
                                                    ...productError,
                                                    name: ''
                                                })

                                                setProductDetails({
                                                    ...productDetails,
                                                    name: value.toUpperCase()
                                                })
                                            }
                                        }}
                                    />
                                    <div>

                                    </div>
                                </div>
                                <label className="block mb-2 text-xs text-red-500"> {productError?.name} </label>
                            </div>
                            {
                                catalog.specification.map((item, i) => {
                                    return (
                                        <div className="mt-4 mb-4" key={i}>
                                            <label className="block mb-2 text-sm"> {item?.text} </label>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-yellow-400 focus:outline-none focus:ring-1 focus:ring-yellow-600"
                                                    placeholder={item?.text}
                                                    value={productDetails?.["specification"]?.[item.text] ? productDetails?.["specification"]?.[item.text] : ""}
                                                    onChange={(e) => {
                                                        var key = item.text
                                                        var value = e.target.value

                                                        setProductDetails({
                                                            ...productDetails,
                                                            specification: {
                                                                ...productDetails.specification,
                                                                [key]: value
                                                            }
                                                        })
                                                    }}
                                                />
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="mt-4 mb-4">
                                <ImageUploading
                                    onChange={(e) => handlepostImage(e, "2d")}
                                    maxNumber={5}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        onImageUpload,
                                    }) => {
                                        return (
                                            <div className="relative h-40">
                                                <p className="block text-sm font-medium text-gray-700">Product Image <span className="mb-2 text-xs text-red-500">{productError?.image}</span> </p>
                                                {
                                                    (productDetails?.image && productDetails?.image?.length !== 0) ?
                                                        (
                                                            <div className="relative">

                                                                <div className="grid grid-cols-5 gap-2 mt-1 w-full h-40 justify-center absolute rounded-md border-2 border-dashed border-yellow-500 px-6 pt-5 pb-6">
                                                                    {productDetails?.image?.length < 5 && <button
                                                                        className="mt-1 w-full h-full flex justify-center absolute rounded-md"
                                                                        onClick={() => onImageUpload()}
                                                                    ></button>}
                                                                    {productDetails?.image?.map(item =>
                                                                        <div className='relative'>
                                                                            <button
                                                                                className='absolute top-0 right-0 bg-white rounded-lg'
                                                                                onClick={() => {
                                                                                    imageDelete(item, "2d")
                                                                                }}>
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px" style={{ margin: 2, zIndex: 0 }}>    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" /></svg>
                                                                            </button>
                                                                            <ProgressiveImage src={item}>
                                                                                {(src) => (
                                                                                    <img
                                                                                        className="object-cover w-full max-h-28"
                                                                                        src={src}
                                                                                        alt="img"
                                                                                    />
                                                                                )}
                                                                            </ProgressiveImage>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="mt-1 w-full flex justify-center absolute rounded-md border-2 border-dashed border-yellow-500 px-6 pt-5 pb-6"
                                                                onClick={() => onImageUpload()}
                                                            >
                                                                <div className="w-full space-y-1 text-center">
                                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                    <div className="w-full text-sm text-gray-600">
                                                                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                                            <span>Upload a file</span>
                                                                        </label>
                                                                        <p className="pl-1">or drag and drop</p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 50MB</p>
                                                                </div>
                                                            </button>
                                                        )
                                                }
                                            </div>
                                        )
                                    }}
                                </ImageUploading>
                            </div>

                            <div className="mt-8 mb-4">
                                <ImageUploading
                                    onChange={(e) => handlepostImage(e, "3d")}
                                    maxNumber={5}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        onImageUpload,
                                    }) => {
                                        return (
                                            <div className="relative h-40">
                                                <p className="block text-sm font-medium text-gray-700">Product 360 Image <span className="mb-2 text-xs text-red-500">{productError?.image}</span> </p>
                                                {
                                                    (productDetails?.image360 && productDetails?.image360?.length !== 0) ?
                                                        (
                                                            <div className="relative">

                                                                <div className="grid grid-cols-5 gap-2 mt-1 w-full h-40 justify-center absolute rounded-md border-2 border-dashed border-yellow-500 px-6 pt-5 pb-6">
                                                                    {productDetails?.image360?.length < 5 && <button
                                                                        className="mt-1 w-full h-full flex justify-center absolute rounded-md"
                                                                        onClick={() => onImageUpload()}
                                                                    ></button>}
                                                                    {productDetails?.image360?.map(item =>
                                                                        <div className='relative'>
                                                                            <button
                                                                                className='absolute top-0 right-0 bg-white rounded-lg'
                                                                                onClick={() => {
                                                                                    imageDelete(item, "3d")
                                                                                }}>
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px" style={{ margin: 2, zIndex: 0 }}>    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" /></svg>
                                                                            </button>
                                                                            <ProgressiveImage src={item}>
                                                                                {(src) => (
                                                                                    <img
                                                                                        className="object-cover w-full max-h-28"
                                                                                        src={src}
                                                                                        alt="img"
                                                                                    />
                                                                                )}
                                                                            </ProgressiveImage>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="mt-1 w-full flex justify-center absolute rounded-md border-2 border-dashed border-yellow-500 px-6 pt-5 pb-6"
                                                                onClick={() => onImageUpload()}
                                                            >
                                                                <div className="w-full space-y-1 text-center">
                                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                    <div className="w-full text-sm text-gray-600">
                                                                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                                            <span>Upload a file</span>
                                                                        </label>
                                                                        <p className="pl-1">or drag and drop</p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 50MB</p>
                                                                </div>
                                                            </button>
                                                        )
                                                }
                                            </div>
                                        )
                                    }}
                                </ImageUploading>
                            </div>
                        </div>

                        <div className="flex w-full">
                            <button
                                className="px-6 py-2 mt-4 w-full text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                onClick={() => {
                                    if (productUpdateStatus === false) {
                                        addProduct()
                                    } else {
                                        updateProduct()
                                    }
                                }}
                            >
                                {productUpdateStatus === false ? "Add New Product" : "Update Product"}
                            </button>
                        </div>

                        <div className="flex flex-col md:flex-row">
                            <div className="flex md:justify-start md:w-1/2 w-full md:mr-2">
                                <button
                                    className="px-6 py-2 mt-4 text-sm w-full lg:w-auto font-medium leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                    onClick={() => hasPrevious()}
                                >
                                    Goto Previous Step
                                </button>
                            </div>

                            <div className="flex md:justify-end md:w-1/2 w-full md:ml-2">
                                <button
                                    className="px-6 py-2 mt-4 text-sm w-full lg:w-auto font-medium leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                    onClick={() => handleNext()}
                                >
                                    Next Step - Review Catalog
                                </button>
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </div>
    )
}
