import { useNavigate } from "react-router-dom";
import ProgressiveImage from 'react-progressive-graceful-image';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

export default function FadeCarousel({ images, imageStyle }) {
    const navigate = useNavigate()

    const navigateToProduct = (item) => {
        if (item?.type === "NewArrivals")
            navigate(`/productdetails?id=${item?.productId}&category=main`)
    }

    return (
        <div>
            <Fade indicators={false} arrows={false} autoplay duration={2000} infinite>
                {images && images.map((slideImage, index) => (
                    <ProgressiveImage key={index} src={slideImage?.image} placeholder={slideImage?.thumbnail}>
                        {(src) => (
                            <img
                                className="relative bg-center bg-no-repeat select-none flex items-center z-0 justify-center h-full w-full"
                                src={src}
                                alt="Banner images"
                                onClick={() => navigateToProduct(images[index])}
                            />
                        )}
                    </ProgressiveImage>
                ))}
            </Fade>
        </div>
    )
}