import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../config/api';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

export default function ChangePassword() {
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const navigate = useNavigate();
    const [tokenExpired, setTokenExpired] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const { token } = useParams();

    const handlePassword = (val) => {
        setPassword(val)
    }
    const handleConfirmPassword = (val) => {
        setConfirmPassword(val)
    }

    useEffect(() => {
        var decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
            setTokenExpired(true)
        }
    }, [])

    const handleSubmit = async (e) => {
        if (!tokenExpired) {
            if (password === confirmPassword) {
                await axios.patch(process.env.REACT_APP_SERVER_API + '/forgotPassword',
                    {
                        token: token,
                        password: password
                    }, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                }).then(function () {
                    Swal.fire({
                        html: 'Password Reset Successful',
                        timer: 1000,
                        position: 'top-end',
                        icon: 'success',
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        navigate("/signIn")
                    })
                }).catch(function (error) {
                });
            }
            else {
                setPasswordError(true)
            }
        }
        e.preventDefault();
    }

    return (
        <div>
            <Header />
            <div className="h-screen mx-auto py-24">
                <div className="flex justify-center">
                    <div className="w-full md:pt-12 xl:w-3/4 lg:w-11/12 flex md:my-32">
                        <div className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg" style={{ backgroundImage: "url('https://wall-empire.s3.ap-south-1.amazonaws.com/Other_Image/06+Zebra+Blind.jpg')" }}></div>
                        <div className="w-full lg:w-1/2 bg-white p-12 rounded-lg lg:rounded-l-none">
                            <div className="px-8 mb-4 text-center">
                                <h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
                                <p className="mb-4 text-sm text-gray-700">
                                    We get it, stuff happens. Just enter your email address below and we'll send you a
                                    link to reset your password!
                                </p>
                            </div>
                            <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                                {
                                    !tokenExpired ?
                                        <div>
                                            <div className="mb-4">
                                                <label className="block mb-2 text-sm font-bold text-gray-700" for="email">New Password</label>
                                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" autoComplete='off' id="password" type="password" required placeholder="New Password" onChange={(e) => handlePassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block mb-2 text-sm font-bold text-gray-700" for="email">Confirm Password</label>
                                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" autoComplete='off' id="confirmPassword" type="password" required placeholder="Confirm Password" onChange={(e) => handleConfirmPassword(e.target.value)}
                                                />
                                            </div>
                                            {
                                                passwordError ?
                                                    <div className="mb-4">
                                                        <label className="block mb-2 text-sm font-bold text-red-500" for="email"> Password does not match</label>
                                                    </div>
                                                    : null
                                            }
                                            <div className="mb-6 text-center">
                                                <button
                                                    className="w-full px-4 py-2 font-bold text-white bg-yellow-500 rounded-full hover:bg-yellow-400 focus:outline-none focus:shadow-outline"
                                                    type="button" onClick={(e) => handleSubmit(e)}
                                                >
                                                    Reset Password
                                                </button>
                                            </div>
                                            <hr className="mb-6 border-t" />
                                            <div className="text-center">
                                                <a className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800" href="/signIn">
                                                    Remember Password? Login
                                                </a>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="mb-4">
                                                <label className="block mb-2 text-lg font-bold text-red-600" for="email">Password reset link is expired.<br />Please try again</label>
                                            </div>
                                            <hr className="mb-6 border-t" />
                                            <div className="text-center">
                                                <a className="inline-block text-sm" href="/forgotpassword">
                                                    <button
                                                        className="w-full px-4 py-2 font-bold text-white bg-yellow-500 rounded-full hover:bg-yellow-400 focus:outline-none focus:shadow-outline"
                                                        type="button"
                                                    >
                                                        Resend Link
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}