import axios from '../config/api';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Modal from '@material-ui/core/Modal'
import { X } from "heroicons-react";
import { S3 } from 'aws-sdk';
import { randomBytes } from 'crypto';
import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function DiscountOffer() {
    const [offer, setOffer] = useState("");
    const [data, setData] = useState({});
    const [popupData, setPopupData] = useState({});
    const [open, setOpen] = useState(false);
    const [userData] = useState(JSON.parse(localStorage.getItem('user')));
    const [updateOffer, setUpdateOffer] = useState("")
    const [openPopupAdd, setPopupAddOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = useState()

    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY
    const [imgUploaded, setImgUploaded] = useState(false)
    const [postImage, setpostImage] = useState('');

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    })

    const getOffer = async () => {
        let res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/discountOffer`,
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            }
        );
        setData(res?.data.data.discountOffer[0])
    }

    const getPopUpOffer = async () => {
        let res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/popOffer`,
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            }
        );
        setPopupData(res?.data.data.popOffer[0])
    }

    useEffect(() => {
        getOffer()
        getPopUpOffer()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        let data = { "offer": offer };
        let res = await axios.post(process.env.REACT_APP_SERVER_API + '/discountOffer', data, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        })
        getOffer()
    }


    const handlePopupSubmit = async (e) => {
        e.preventDefault();

        let data = { "image_url": imageUrl };
        let res = await axios.post(process.env.REACT_APP_SERVER_API + '/popOffer', data, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        })
        if (res.status === 200) {
            getPopUpOffer()
            handleClose()
            setpostImage()
            setImageUrl()
            setImgUploaded(false)
        }
    }

    const handleUpdate = async () => {
        setOpen(true)
        {
            if (updateOffer) {
                let updateData = { "offer_id": data._id, "offer": updateOffer }
                let res = await axios.put(process.env.REACT_APP_SERVER_API + '/discountOffer', updateData, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                }).then(() => {
                    setOpen(false)
                    getOffer()
                    setUpdateOffer("")
                })
            }
        }
    }

    const handlePopupImage = (e) => {
        const [file] = e.target.files;
        setpostImage(URL.createObjectURL(file));
        setImgUploaded(true)
        imageUpload(e.target.files[0], (url) => {
            setImageUrl(url)
            setImgUploaded(true)
        })
    };

    const imageUpload = async (imageFile, callback) => {
        const rawBytes = await randomBytes(16)
        const imageName = rawBytes.toString('hex')

        const uploadURL = await s3.getSignedUrlPromise('putObject', {
            Bucket: bucketName,
            Key: "banners/" + imageName,
            Expires: 60
        })

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: imageFile
        })

        const imageUrl = uploadURL.split('?')[0]
        callback(imageUrl)
    };

    const handleDelete = async (popup) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                imageDelete(popup.image.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))

                const bannerRes = axios.delete(process.env.REACT_APP_SERVER_API + `/popOffer?pop_id=${popup._id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })
                if (bannerRes.status === 200) {
                    getPopUpOffer()
                }
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    icon: 'success',
                    timer: 1000,
                    showConfirmButton: false
                })
                getPopUpOffer()
            }
        })
    }

    const imageDelete = (imageFile) => {
        s3.deleteObject({
            Bucket: bucketName,
            Key: imageFile
        }, function (err, data) {
        })
    }

    const handleOpen = () => {
        setPopupAddOpen(true);
    };

    const handleClose = () => {
        setPopupAddOpen(false);
        setpostImage()
        setImageUrl()
        setImgUploaded(false)
    };

    return (
        <>
            <Header />
            <Modal
                className='w-full flex justify-center'
                open={openPopupAdd}
                onClose={handleClose}
            >
                <div className='bg-white m-0 md:m-10 p-10 w-full md:w-3/4'>
                    <div className="flex justify-between items-center mb-10">
                        <div className="mr-4">
                            <h1 className="font-weight-bold text-3xl">Offer Image</h1>
                        </div>
                        <X
                            onClick={() => handleClose()}
                            className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
                        />
                    </div>
                    {
                        imgUploaded ?
                            <div className="flex justify-center items-center w-full">
                                <ProgressiveImage src={postImage}>
                                    {(src) => (
                                        <img src={src} alt="" width="400px" height="200px" />
                                    )}
                                </ProgressiveImage>
                            </div>
                            :
                            <div className="flex justify-center items-center w-full">
                                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or (MAX. 800 x 400px)</p>
                                    </div>
                                    <input id="dropzone-file" type="file" onChange={handlePopupImage} className="hidden" />
                                </label>
                            </div>
                    }
                    <div className="mt-10 w-full md:w-1/4">
                        <button form="hook-form" type="submit" className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg" onClick={(e) => { handlePopupSubmit(e) }}>
                            Create
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="pl-4 pr-4 pt-32 bg-gray-200 min-h-screen">
                <div className="mb-1">
                    <div className="font-extrabold text-2xl text-center"> DISCOUNT OFFER SECTION</div>
                    <div className="text-center">Creation And Modification Part Of DiscountOffer</div>
                </div>
                <div className="max-w-full mx-auto pt-24 px-6 min-h-screen">
                    <div className="flex flex-col">
                        {
                            data ?
                                <div>
                                    <h1 className='text-base py-3 font-bold'>Top Offer</h1>
                                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                                        <div className="inline-block min-w-full align-middle">
                                            <table className="min-w-full">
                                                <thead>
                                                    <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                                                        <th className="py-3 px-6 text-left">Offer</th>
                                                        <th className="py-3 px-6 text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tr key={data?._id} className="border-b border-gray-200 bg-white ">
                                                    {
                                                        open ?
                                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <input className="w-full text-sm p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='offer' onChange={(e) => setUpdateOffer(e.target.value)} defaultValue={data.offer} autoComplete='off' type="text" placeholder="Enter your Discount Offer" required />
                                                                </div>
                                                            </td>
                                                            :
                                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                <div className="flex text-base items-center">
                                                                    {data.offer}
                                                                </div>
                                                            </td>
                                                    }
                                                    <td className="py-3 px-2 flex justify-center whitespace-nowrap">
                                                        <button form="hook-form" type="submit" onClick={handleUpdate} className="bg-yellow-500 align-middle content-center w-28 text-gray-100 p-2 rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg">
                                                            {open ? "Update" : "Edit"}
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="overflow-x-auto shadow-md sm:rounded-lg">
                                            <div className="inline-block min-w-full align-middle bg-gray-50">
                                                <h3 className="pt-4 text-2xl text-center font-semibold">
                                                    Create Discount Offer
                                                </h3>
                                                <form onSubmit={e => { handleSubmit(e) }}>
                                                    <div className="pt-10 pl-10 pr-10">
                                                        <div className="font-bold text-xl text-gray-700 tracking-wide">Offer <span className="text-red-600">*</span></div>
                                                        <input className="w-full text-sm p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='offer' onChange={(e) => setOffer(e.target.value)} autoComplete='off' type="text" placeholder="Enter your Discount Offer" required />
                                                    </div>
                                                    <div className="p-8">
                                                        <button type='submit' className="bg-yellow-500 text-gray-100 p-3 w-40 rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg">
                                                            Add Offer
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className='mt-10'>
                            <h1 className='text-base py-3 font-bold'>Popup Offer</h1>
                            <div className="w-full flex justify-center md:justify-end py-6 sm:pr-10">
                                <button className={popupData?.image ? 'p-4 pt-3 pb-3 cursor-default rounded-xl w-full md:w-40 bg-gray-300 text-black' : 'p-4 pt-3 pb-3 rounded-xl w-full md:w-40 bg-yellow-500 hover:bg-yellow-600 text-white'} onClick={handleOpen} disabled={popupData?.image}>Create Popup Offer</button>
                            </div>
                            <h1 className='text-base py-3 text-red-500'>Image size must be 760 * 490</h1>
                            <div className="overflow-x-auto shadow-md sm:rounded-lg">
                                <div className="inline-block min-w-full align-middle">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                                                <th className="py-3 px-6 text-left">Offer Image</th>
                                                <th className="py-3 px-6 text-center">Action</th>
                                            </tr>
                                        </thead>
                                        {
                                            popupData?.image ?
                                                <tr key={popupData?._id} className="border-b border-gray-200 bg-white ">
                                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                                        <ProgressiveImage src={popupData.image}>
                                                            {(src) => (
                                                                <img src={src} width="200" alt="Image not found" />
                                                            )}
                                                        </ProgressiveImage>
                                                    </td>
                                                    <td className="py-3 px-2 flex justify-center whitespace-nowrap">
                                                        <div className="w-4 transform hover:text-yellow-400 cursor-pointer hover:scale-110">
                                                            <svg fill="currentColor" onClick={() => handleDelete(popupData)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" stroke='currentColor' width="20px" height="20px"><path d="M 20.5 4 A 1.50015 1.50015 0 0 0 19.066406 6 L 14.640625 6 C 12.803372 6 11.082924 6.9194511 10.064453 8.4492188 L 7.6972656 12 L 7.5 12 A 1.50015 1.50015 0 1 0 7.5 15 L 8.2636719 15 A 1.50015 1.50015 0 0 0 8.6523438 15.007812 L 11.125 38.085938 C 11.423352 40.868277 13.795836 43 16.59375 43 L 31.404297 43 C 34.202211 43 36.574695 40.868277 36.873047 38.085938 L 39.347656 15.007812 A 1.50015 1.50015 0 0 0 39.728516 15 L 40.5 15 A 1.50015 1.50015 0 1 0 40.5 12 L 40.302734 12 L 37.935547 8.4492188 C 36.916254 6.9202798 35.196001 6 33.359375 6 L 28.933594 6 A 1.50015 1.50015 0 0 0 27.5 4 L 20.5 4 z M 14.640625 9 L 33.359375 9 C 34.196749 9 34.974746 9.4162203 35.439453 10.113281 L 36.697266 12 L 11.302734 12 L 12.560547 10.113281 A 1.50015 1.50015 0 0 0 12.5625 10.111328 C 13.025982 9.4151428 13.801878 9 14.640625 9 z M 11.669922 15 L 36.330078 15 L 33.890625 37.765625 C 33.752977 39.049286 32.694383 40 31.404297 40 L 16.59375 40 C 15.303664 40 14.247023 39.049286 14.109375 37.765625 L 11.669922 15 z" /></svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                                : <tr key={popupData?._id} className="border-b border-gray-200 bg-white ">
                                                    <td className="py-3 px-2 flex justify-center whitespace-nowrap">
                                                        No records found
                                                    </td>
                                                    <td></td>
                                                </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}