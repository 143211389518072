import { ArrowCircleLeft } from 'heroicons-react';
import { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import S3 from 'aws-sdk/clients/s3'
import { randomBytes } from 'crypto'
import { WithContext as ReactTags } from 'react-tag-input';
import { useNavigate } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-graceful-image';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import readXlsxFile from 'read-excel-file';

export default function Step1_CreateCatalogue({ catalogDetails, setCatalogDetails, setProducts, hasNext }) {
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    })

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();
    const designNumberDefault = { id: "design", text: "Design Number" }
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [catalogError, setCatalogError] = useState({})
    const [visibleUpload, setVisibleUpload] = useState(true)

    const checkCatalogImageUpload = (upload) => {
        var errorCount = 1
        var error = JSON.parse(JSON.stringify(catalogError))

        if (catalogDetails?.name === "") {
            error = {
                ...error,
                name: "Enter Catalog Name"
            }
        } else {
            error = {
                ...error,
                name: ""
            }
            errorCount = errorCount - 1
        }
        setCatalogError({
            ...error
        })
        if (errorCount === 0) {
            upload()
        }
    }

    const handlepostImage = (type, imageList, index) => {
        if (type === 'catalog') {
            if (catalogDetails?.image !== '') {
                imageDelete(catalogDetails.image.replace("https://wall-empire.s3.ap-south-1.amazonaws.com/", ""))
            }
            imageUpload(imageList[0], catalogDetails?.name + "/", (url) => {
                setCatalogDetails({
                    ...catalogDetails,
                    image: url
                })
            })
        }
    };

    const imageDelete = (imageFile) => {
        s3.deleteObject({
            Bucket: bucketName,
            Key: imageFile
        }, function (err, data) {
            return data
        })
    }

    const imageUpload = async (imageFile, path, callback) => {
        const rawBytes = await randomBytes(16)
        const imageName = rawBytes.toString('hex')

        const uploadURL = await s3.getSignedUrlPromise('putObject', {
            Bucket: bucketName,
            Key: "Catalog/" + path + imageName + "." + imageFile?.file?.name?.split(".")[1],
            Expires: 60
        })

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: imageFile.file
        })

        const imageUrl = uploadURL.split('?')[0]
        callback(imageUrl)
    };

    const handleNext = () => {
        var error = JSON.parse(JSON.stringify(catalogError))

        if (catalogDetails?.name === "") {
            error = {
                ...error,
                name: "Enter Catalog Name"
            }
        } else {
            error = {
                ...error,
                name: ""
            }
        }

        setCatalogError({
            ...error
        })

        const errorCount = Object.keys(error).filter(item => error[item] !== "").length
        if (errorCount === 0) {
            hasNext(true)
        } else {
            hasNext(false)
        }
    }

    const excelToJson = (row) => {
        const header = row[0]
        const rows = row.slice(1)
        return rows.map((rowData) => {
            const record = {}
            rowData.map((item, index) => {
                record[header[index]] = item
            })
            return record
        })
    }

    const readExcel = (e) => {
        if (e.target.files[0]) {
            readXlsxFile(e.target.files[0]).then(async (rows) => {
                const excelData = await excelToJson(rows)
                excelData.map((excel, i) => {
                    const productSpecification = {}
                    catalogDetails.specification.map(item => {
                        productSpecification[item.text] = excel[item.text]
                    })
                    setProducts(products => [
                        ...products,
                        {
                            name: excel[designNumberDefault.text],
                            specification: productSpecification,
                            image: [],
                            image360: []
                        }
                    ])
                })
            })
        }
    }

    const handleDelete = i => {
        setCatalogDetails({
            ...catalogDetails,
            specification: [
                ...catalogDetails.specification.filter((tag, index) => index !== i)
            ]
        })
    };

    const handleAddition = tag => {
        setCatalogDetails({
            ...catalogDetails,
            specification: [
                ...catalogDetails.specification,
                tag
            ]
        })
    };

    return (
        <div className='px-5'>
            <button className='flex p-2 items-center text-md lg:w-auto leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none' onClick={() => navigate("/catalog")}>
                <ArrowCircleLeft
                    className=""
                /><span className='ml-2'>Back to Catalog</span>
            </button>
            <div className="flex flex-col md:flex-row p-5">
                <div className="h-full lg:w-1/2 lg:visible invisible w-0 flex items-center justify-center">
                    {
                        catalogDetails?.name !== "" || catalogDetails?.image !== "" ?
                            (
                                <div className='lg:visible invisible'>
                                    <div className="mb-4 flex items-center justify-center">
                                        <label className="text-2xl">Your Catalog Getting Ready</label>
                                    </div>
                                    <div className="w-96 max-h-96 rounded-2xl shadow-lg mb-4">
                                        <ProgressiveImage src={catalogDetails?.image === "" ? "https://cdn.pixabay.com/photo/2021/01/15/17/01/green-5919790__340.jpg" : catalogDetails?.image} placeholder={'tiny-image.jpg'}>
                                            {(src) => (
                                                <img
                                                    className="object-contain rounded-2xl w-96 max-h-96"
                                                    src={src}
                                                    alt="img"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <label className="text-2xl">{catalogDetails?.name === "" ? "Catalog" : catalogDetails?.name}</label>
                                    </div>
                                </div>
                            ) :
                            (
                                <ProgressiveImage src="https://cdn.pixabay.com/photo/2021/01/15/17/01/green-5919790__340.jpg">
                                    {(src) => (
                                        <img
                                            className="object-cover w-full h-full"
                                            src={src}
                                            alt="img"
                                        />
                                    )}
                                </ProgressiveImage>
                            )
                    }
                </div>
                <div className="flex items-center justify-center px-6 sm:w-full lg:w-1/2 w-full">
                    <div className="w-full">
                        <h3 className="mb-4 text-xl font-bold text-yellow-600">
                            Create New Catalog
                        </h3>

                        <div className="w-full bg-gray-200 rounded-full">
                            <div className={`w-1/3 p-1 text-xs font-medium leading-none text-center text-yellow-100 bg-yellow-600 rounded-full`}
                            >
                                Step 1
                            </div>
                        </div>

                        <div>
                            <div className="mt-4 mb-4">
                                <label className="block mb-2 text-sm"> Catalog Name </label>
                                <input
                                    type="text"
                                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-yellow-400 focus:outline-none focus:ring-1 focus:ring-yellow-600"
                                    placeholder="Catalog Name"
                                    value={catalogDetails?.name}
                                    onChange={(e) => {
                                        setCatalogError({
                                            ...catalogError,
                                            name: ''
                                        })
                                        setCatalogDetails({
                                            ...catalogDetails,
                                            name: e.target.value
                                        })
                                    }}
                                />
                                <label className="block mb-2 text-xs text-red-500"> {catalogError?.name} </label>
                            </div>
                            <div className="mt-4 mb-4">
                                <label className="block mb-2 text-sm"> Field Names (Multiple) </label>
                                <ReactTags
                                    tags={catalogDetails.specification}
                                    classNames={{
                                        tags: 'tagsClass',
                                        tagInput: 'mb-3',
                                        tagInputField: 'w-full px-4 py-2 text-sm border rounded-md focus:border-yellow-400 focus:outline-none focus:ring-1 focus:ring-yellow-600',
                                        tag: 'px-4 py-2 mr-2 rounded-full text-gray-500 bg-gray-200 font-semibold text-sm w-max cursor-pointer active:bg-gray-300 transition duration-300 ease',
                                        remove: 'pl-2',
                                    }}
                                    placeholder="Enter Field Name"
                                    delimiters={delimiters}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    allowDragDrop={false}
                                    inputFieldPosition="top"
                                    autocomplete
                                />
                            </div>
                            <ExcelFile filename={catalogDetails?.name ? catalogDetails?.name : "Catalog"} element={
                                <button type="button" disabled={catalogDetails.specification.length > 0 ? false : true} className={`px-6 pt-2.5 pb-2 ${catalogDetails.specification.length > 0 ? "bg-yellow-500 hover:bg-yellow-600 focus:bg-yellow-600" : "bg-gray-500 cursor-not-allowed"} text-white font-medium text-xs leading-normal uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out flex align-center`} onClick={() => setVisibleUpload(true)}>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                                        className="w-4 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                            d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z">
                                        </path>
                                    </svg>
                                    Download Excel
                                </button>
                            }>
                                <ExcelSheet data={[
                                    designNumberDefault,
                                    ...catalogDetails.specification
                                ]} name="Catalog">
                                    {
                                        [
                                            designNumberDefault,
                                            ...catalogDetails.specification
                                        ].map((tag) => {
                                            return (
                                                <ExcelColumn label={tag?.text} value={tag?.text} />
                                            )
                                        })
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                            <div className="mt-4 mb-4">
                                <label className="block mb-2 text-sm text-red-500">*Please do not modify Excel file header.</label>
                            </div>
                            {
                                visibleUpload ?
                                    <div className="mt-4 mb-4">
                                        <div className="flex justify-center">
                                            <div className="mb-3 w-full">
                                                <label for="formFile" className="block mb-2 text-sm">Import Excel</label>
                                                <input className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="excelFile" accept=".xlsx, .xls" onChange={(e) => {
                                                    readExcel(e)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="mt-4 mb-4">
                                <ImageUploading
                                    onChange={(e) => handlepostImage('catalog', e, 1)}
                                    maxNumber={1}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        onImageUpload,
                                    }) => {
                                        return (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Catalog Image</label>
                                                <button
                                                    className="mt-1 w-full flex justify-center rounded-md border-2 border-dashed border-yellow-500 px-6 pt-5 pb-6"
                                                    onClick={() => checkCatalogImageUpload(onImageUpload)}
                                                >
                                                    <div className="w-full space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="w-full text-sm text-gray-600">
                                                            <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                                <span>Upload a file</span>
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                    }}
                                </ImageUploading>
                            </div>
                        </div>

                        <div className="flex md:justify-end w-full lg:ml-2">
                            <button
                                className="px-6 py-2 mt-4 text-sm font-medium w-full lg:w-auto leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                onClick={() => handleNext()}
                            >
                                Next Step - Import Excel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
