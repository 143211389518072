import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import { useLocation } from "react-router-dom";
import { StyleRoot } from "radium";
import axios from "../config/api";
import ProgressiveImage from "react-progressive-graceful-image";
import Modal from "@material-ui/core/Modal";
import { X } from "heroicons-react";
import Image360Icon from "../resources/images/360-degrees.svg";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import Logo from "../resources/images/WE-full-logo.png";
import CatalogDetail from "../resources/images/catalog-detail.jpeg";
import S3 from "aws-sdk/clients/s3";

export default function CatalogDetails() {
  const search = useLocation().search;
  const [catalog, setCatalog] = useState();
  const [specKeys, setSpecKeys] = useState([]);
  const catalogueNumber = new URLSearchParams(search).get("catalogueNumber");
  const CProductNumber = new URLSearchParams(search).get("CProductNumber");
  const [open, setOpen] = useState(false);
  const [open360, setOpen360] = useState(false);
  const [product, setProduct] = useState();
  const [image360, setimage360] = useState("");

  const pageProcess = async () => {
    setCatalog([]);
    if (catalogueNumber) await getCatalog();
    if (CProductNumber) await getCatalogProduct();
  };

  useEffect(() => {
    pageProcess();
  }, []);

  const getCatalog = async () => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_API +
        `/catalogue?catalogueNumber=${catalogueNumber}`,
      {
        headers: {
          authorization: localStorage.getItem("_token"),
        },
      }
    );
    if (res.status === 200) {
      setCatalog(res.data.data[0]);
    }
  };

  const getCatalogProduct = async () => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_API +
        `/catalogue-product?CProductNumber=${CProductNumber}`,
      {
        headers: {
          authorization: localStorage.getItem("_token"),
        },
      }
    );
    if (res.status === 200) {
      setCatalog(res.data.data[0]);
      setProduct(res.data.data[0]);
      if (res.data.data[0].specification)
        setSpecKeys(Object.keys(res.data.data[0].specification));
    }
  };

  const handleOpen = (item) => {
    setOpen(true);
    setProduct(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen360 = async (image) => {
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
    const region = process.env.REACT_APP_AWS_BUCKET_REGION;
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY;

    const imageurl = new URL(image);
    const key = decodeURIComponent(imageurl.pathname.substring(1));

    const s3 = new S3({
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    });

    const params = {
      Bucket: bucketName,
      Key: key,
      ResponseContentType: "image/jpeg",
    };

    try {
      const response = await s3.getObject(params).promise();
      const imageData = `data:image/jpeg;base64,${response.Body.toString(
        "base64"
      )}`;
      setimage360(imageData);
    } catch (error) {
      console.error(error);
    }
    setOpen360(true);
    setOpen(false);
  };

  const handleClose360 = () => {
    setOpen360(false);
    setOpen(true);
    setimage360("");
  };

  return (
    <StyleRoot>
      <div>
        <Header />
        <div className="min-h-full pt-32">
          <div className="px-2">
            {catalogueNumber ? (
              <div>
                {process.env.REACT_APP_IS_MAIN_CATALOG_ENABLED === "true" ? (
                  <div>
                    <div className="mb-2 font-bold text-base text-center bg-gray-300 rounded-md p-2 md:text-md lg:text-lg">
                      Catalog Number : {catalogueNumber}
                    </div>
                    <div className="w-full">
                      <ProgressiveImage src={catalog?.image}>
                        {(src) => (
                          <img
                            src={src}
                            alt=""
                            className="max-h-screen bg-contain w-full"
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                    <div className="w-full">
                      {catalog?.catalogueProducts?.map((product, index) => {
                        return (
                          <div key={index} className=" py-5">
                            <div className="w-full bg-gray-300 rounded-lg text-center">
                              <div className="pt-2 pl-2 text-base md:text-md lg:text-lg md:flex">
                                <div className="w-full md:w-2/3 md:text-right">
                                  Product Name : {product.name}
                                </div>
                                {product?.image360.length > 0 ? (
                                  <div className="w-full md:w-1/2 flex justify-end px-5">
                                    <button
                                      className="p-2 rounded-xl bg-yellow-500 hover:bg-yellow-600 text-white flex justify-center items-center"
                                      onClick={() => {
                                        handleOpen(product);
                                      }}
                                    >
                                      <img
                                        src={Image360Icon}
                                        className="w-10"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                ) : null}
                              </div>

                              <div
                                id={product.number}
                                className="carousel carousel-fade flex"
                                data-interval="false"
                                data-bs-interval="false"
                              >
                                <div className="carousel-inner p-2 relative w-full overflow-hidden">
                                  {product.image.map((img, i) => {
                                    return (
                                      <div
                                        className={`carousel-item ${
                                          i == 0 ? "active" : ""
                                        } relative float-left w-full`}
                                        key={i}
                                      >
                                        <ProgressiveImage src={img}>
                                          {(src) => (
                                            <img
                                              src={src}
                                              className="block bg-contain m-auto h-96 md:h-screen "
                                              alt={catalogueNumber}
                                            />
                                          )}
                                        </ProgressiveImage>
                                      </div>
                                    );
                                  })}
                                </div>
                                {product.image.length > 1 ? (
                                  <>
                                    <button
                                      className="top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                                      type="button"
                                      data-bs-target={"#" + product.number}
                                      data-bs-slide="prev"
                                    >
                                      <span
                                        className="inline-block bg-no-repeat"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                          className="h-7 md:h-10 absolute text-yellow-400 flex z-10 left-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                                            clip-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>
                                    <button
                                      className="top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                                      type="button"
                                      data-bs-target={"#" + product.number}
                                      data-bs-slide="next"
                                    >
                                      <span
                                        className="inline-block bg-no-repeat"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                          className="h-7 md:h-10 absolute text-yellow-400 flex z-10 right-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                            clip-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
                      <ProgressiveImage src={CatalogDetail}>
                        {(src) => (
                          <img
                            src={src}
                            alt={catalogueNumber}
                            className="h-full w-full object-cover"
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full">
                {product?.image360.length > 0 ? (
                  <div className="w-full flex justify-end px-5">
                    <button
                      className="p-2 rounded-xl bg-yellow-500 hover:bg-yellow-600 text-white flex justify-center items-center"
                      onClick={() => {
                        handleOpen(catalog);
                      }}
                    >
                      <img src={Image360Icon} className="w-10" alt="" />
                    </button>
                  </div>
                ) : null}
                <div
                  id="carouselExampleIndicators"
                  className="carousel carousel-fade flex"
                  data-interval="false"
                  data-bs-interval="false"
                >
                  <div className="carousel-inner relative w-full overflow-hidden">
                    {catalog?.image?.map((img, index) => {
                      return (
                        <div
                          className={`carousel-item ${
                            index == 0 ? "active" : ""
                          } relative float-left w-full`}
                        >
                          <ProgressiveImage src={img}>
                            {(src) => (
                              <img
                                src={src}
                                className="block m-auto h-96 md:h-screen "
                                alt={CProductNumber}
                              />
                            )}
                          </ProgressiveImage>
                        </div>
                      );
                    })}
                  </div>
                  {catalog?.image?.length > 1 ? (
                    <>
                      <button
                        className="top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="inline-block bg-no-repeat"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            className="h-7 md:h-10 absolute text-yellow-400 flex z-10 left-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="inline-block bg-no-repeat"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            className="h-7 md:h-10 absolute text-yellow-400 flex z-10 right-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </>
                  ) : null}
                </div>
                <hr />
                <hr />
                <div className="row py-2 lg:w-1/2 px-2 sm:px-5 m-auto">
                  <div className="font-serif text-center place-self-center flex justify-between">
                    <h1 className="text-lg md:text-xl text-black font-bold">
                      Product Details
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-x-auto">
                          <table className="min-w-full">
                            <tbody>
                              <tr className="bg-white border-0">
                                <td className="whitespace-nowrap" colSpan={2}>
                                  <div className="place-self-center">
                                    <h1 className="text-sm text-gray-400 font-bold">
                                      {catalog?.name}
                                    </h1>
                                  </div>
                                </td>
                              </tr>
                              <tr className="border-0">
                                <td className="whitespace-nowrap" colSpan={2}>
                                  <div className="place-self-center">
                                    <h1 className="text-xl md:text-2xl text-gray-900 font-bold">
                                      {catalog?.number}
                                    </h1>
                                  </div>
                                </td>
                              </tr>
                              <tr className="border-b bg-gray-300">
                                <td
                                  className="text-lg md:text-xl font-bold text-gray-900 px-6 py-4 whitespace-nowrap rounded-t-lg"
                                  colSpan={2}
                                >
                                  Specification
                                </td>
                              </tr>
                              {specKeys.length > 0 ? (
                                specKeys?.map((spec, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap capitalize font-bold">
                                        {spec}
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4">
                                        {catalog?.specification?.[spec]}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                  <td className="text-sm text-center text-gray-900 px-6 py-4 whitespace-nowrap capitalize font-bold">
                                    No specification found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          className="overflow-x-auto h-screen"
        >
          <div className="bg-white m-auto p-5 overflow-y-auto h-screen">
            <div className="w-full flex justify-between bg-white p-5 sticky-top">
              <h1 className="font-weight-bold text-center text-3xl">
                360 View
              </h1>
              <X
                onClick={() => handleClose()}
                className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
              />
            </div>
            <div className={`md:grid grid-cols-2 gap-4 cursor-pointer`}>
              {product?.image360.map((images, i) => {
                return (
                  <div className="mb-4" onClick={() => handleOpen360(images)}>
                    <img src={images} height={"50vh"} width={"100%"} />
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>

        <Modal
          className="h-screen"
          open={open360 && image360 !== ""}
          onClose={handleClose360}
        >
          <div className="bg-white m-auto h-screen">
            <ReactPhotoSphereViewer
              src={image360}
              height={"100vh"}
              width={"100%"}
              navbar={{
                zoomInButton: {
                  className: "custom-zoom-in-button",
                },
              }}
            />
            <div className="w-full absolute top-4 right-4 flex justify-end bg-transparent pb-2 z-50">
              <X
                onClick={() => handleClose360()}
                style={{
                  border: "1px solid white",
                }}
                className="cursor-pointer z-50 text-white rounded-full border-white bg-black"
              />
            </div>
            <div className="absolute top-4 left-4 flex justify-end pb-2">
              <div className="cursor-pointer z-50 rounded-full">
                <img className="h-8 w-auto" src={Logo} alt="wallempire logo" />
              </div>
            </div>
          </div>
        </Modal>
        <Footer />
      </div>
    </StyleRoot>
  );
}
