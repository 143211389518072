import React, { useEffect, useRef, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import axios from "../../config/api";
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid";
import { motion } from "framer-motion";
import VideoPlayer from "./VideoPlayer";

export default function Banner() {
  const sliderChangeDuration = 2000;
  const videoContainerRef = useRef(null);
  const [bannerData, setBannerData] = useState([]);
  //   const [bannerWithHTML, setBannerWithHTML] = useState([]);
  const [bannerIndex, setBannerIndex] = useState(-1);

  useEffect(() => {
    let clearTimeoutId;
    if (
      bannerData.length > 0 &&
      bannerData[bannerIndex]?.mediaType !== "video"
    ) {
      clearTimeoutId = setTimeout(() => {
        nextBanner();
      }, sliderChangeDuration);
    }

    return () => clearTimeout(clearTimeoutId);
  }, [bannerIndex]);

  useEffect(() => {
    setBannerIndex(0);
    bannerhandler();
  }, []);

  const bannerhandler = async () => {
    const bannerRes = await axios.get(
      process.env.REACT_APP_SERVER_API + "/banner?type=Main",
      {
        headers: {
          authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
        },
      }
    );

    if (bannerRes.status === 200) {
      const banner = [...bannerRes.data.data.sort((a, b) => a.order - b.order)];
      setBannerData(banner);
    }
  };

  const nextBanner = () => {
    setBannerIndex((bannerIndex + 1) % bannerData.length);
  };

  const previousBanner = () => {
    setBannerIndex(bannerIndex === 0 ? bannerData.length - 1 : bannerIndex - 1);
  };

  const BannerItem = () => {
    const banner = bannerData[bannerIndex];
    const index = bannerIndex;

    if (banner?.mediaType === "image") {
      return (
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="relative select-none flex items-center z-0 justify-center h-72 sm:h-72 md:h-full lg:h-screen 2xl:h-screen w-full"
        >
          <ProgressiveImage
            src={banner?.image}
            placeholder={banner?.thumbnail}
            key={index}
          >
            {(src) => (
              <img
                className="relative select-none flex items-center z-0 justify-center h-72 sm:h-72 md:h-full lg:h-screen 2xl:h-screen w-full"
                src={src}
                alt="Banner images"
                key={index}
              />
            )}
          </ProgressiveImage>
        </motion.div>
      );
    } else {
      return (
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="relative select-none flex items-center z-0 justify-center h-72 sm:h-72 md:h-full lg:h-screen 2xl:h-screen w-full"
        >
          <VideoPlayer
            autoplay={true}
            playbackRates={[0.5, 1, 1.25, 1.5, 2]}
            width={videoContainerRef?.current?.offsetWidth}
            height={videoContainerRef?.current?.offsetHeight}
            controls={false}
            onReady={(player) => {
              player.play();
            }}
            onEnded={() => {
              setBannerIndex(index + 1);
            }}
            sources={[
              {
                src: banner?.image,
                type: "video/mp4",
              },
            ]}
            className="absolute top-0 left-0 w-full h-full object-cover"
            alt="Banner video"
          />
        </motion.div>
      );
    }
  };

  return (
    <div ref={videoContainerRef} className="relative flex w-full h-72 sm:h-72 md:h-full lg:h-screen justify-center items-center 2xl:h-screen">
      <BannerItem />
      <button onClick={previousBanner}  className="h-7 w-7 md:h-10 md:w-10 absolute text-yellow-400 flex z-10 top-1/2 left-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none">
        <ArrowCircleLeftIcon />
      </button>
      <button onClick={nextBanner} className="h-7 w-7 md:h-10 md:w-10 absolute text-yellow-400 flex z-10 top-1/2 right-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none">
        <ArrowCircleRightIcon />
      </button>
    </div>
  );
}
