import React, { useState, useEffect, useRef } from 'react'
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import axios from '../config/api';
import { useNavigate } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import { X } from 'heroicons-react';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export default function User() {
    const ref = useRef();
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [pageCount] = useState(0);
    const [itemOffset] = useState(20);
    const [scrollValue, setScrollValue] = useState(0);
    const [mobileScreenStatus, setMobileScreenStatus] = useState(false)
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(1)
    const [hasMoreRecords, setHasMoreRecords] = useState(false)
    const [productNotFound, setProductNotFound] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')

    //User add
    const [role, setRole] = useState('user')
    const [username, setusername] = useState('')
    const [company, setcompany] = useState('')
    const [gst, setGst] = useState('')
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [open, setOpen] = useState(false);
    const [validatePassword, setValidatePassword] = useState(true);

    const rolehandleChange = (e) => {
        setRole(e.target.value)
    }

    const usernamehandleChange = (e) => {
        setusername(e.target.value)
    }

    const companyhandleChange = (e) => {
        setcompany(e.target.value)
    }

    const gsthandleChange = (e) => {
        setGst(e.target.value)
    }

    const phonehandleChange = (e) => {
        setphone(e.target.value)
    }

    const emailhandleChange = (e) => {
        setemail(e.target.value)
    }

    const passwordhandleChange = (e) => {
        setpassword(e.target.value)
    }

    const confirmPasswordhandleChange = (e) => {
        setconfirmPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        if (password !== confirmPassword) {
            setValidatePassword(false)
        }
        else {
            setValidatePassword(true)
        }
        if (password === confirmPassword) {
            axios.post(process.env.REACT_APP_SERVER_API + '/signUp', {
                role: role,
                username: username,
                phoneNumber: phone,
                companyName: company,
                email: email,
                password: password,
                confirmPassword: confirmPassword
            }, {
                headers: {
                    authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
                }
            })
                .then(function (response) {
                    setusername('')
                    setcompany('')
                    setGst('')
                    setphone('')
                    setemail('')
                    setpassword('')
                    setconfirmPassword('')
                    setOpen('')
                    setValidatePassword(true)
                    handleClose()
                })
                .catch(function (error) {
                });
        }
        e.preventDefault();
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const active = (id, event) => {
        axios.patch(process.env.REACT_APP_SERVER_API + '/activate', {
            user_id: id,
            active: event.target.checked,
            page: pageCount,
            limit: itemOffset
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        }).then(function (response) {
            getUser()
        }).catch(function (error) {
        });
    };
    const ban = (id, event) => {
        axios.patch(process.env.REACT_APP_SERVER_API + '/activate', {
            user_id: id,
            ban: event.target.checked,
            page: pageCount,
            limit: itemOffset
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        }).then(function (response) {
            getUser()
        }).catch(function (error) {
        });
    };
    const isHome = (id, event) => {
        axios.patch(process.env.REACT_APP_SERVER_API + '/activate', {
            user_id: id,
            isHome: event.target.checked,
            page: pageCount,
            limit: itemOffset
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        }).then(function (response) {
            getUser()
        }).catch(function (error) {
        });
    };
    const isProduct = (id, event) => {
        axios.patch(process.env.REACT_APP_SERVER_API + '/activate', {
            user_id: id,
            isProduct: event.target.checked,
            page: pageCount,
            limit: itemOffset
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        }).then(function (response) {
            getUser()
        }).catch(function (error) {
        });
    };
    const isEnquiry = (id, event) => {
        axios.patch(process.env.REACT_APP_SERVER_API + '/activate', {
            user_id: id,
            isEnquiry: event.target.checked,
            page: pageCount,
            limit: itemOffset
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        }).then(function (response) {
            getUser()
        }).catch(function (error) {
        });
    };

    useEffect(() => {
        getUser()
    }, [pageCount])

    const getUser = () => {
        let userData = JSON.parse(localStorage.getItem('user'));
        if (userData === null || userData?.user?.role === 'user') {
            navigate('/')
        } else {
            axios.get(process.env.REACT_APP_SERVER_API + '/users?page=' + pageCount + '&limit=' + itemOffset, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            }).then(function (response) {
                setUser(response.data.data.user)
                setTotalCount(response.data.data.user.length)
            }).catch(function (error) {
            });
        }
    }

    const getPaginatedUsers = async () => {
        let userData = JSON.parse(localStorage.getItem('user'));
        if (userData == null) {
            navigate('/')
        } else {
            const userRes = await axios.get(process.env.REACT_APP_SERVER_API + '/users?page=' + page + '&limit=' + itemOffset, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (userRes.status === 200) {
                setUser([...user, ...userRes.data.data.user])
            }
        }
    }

    useEffect(() => {
        getSearchedUsers()
    }, [searchQuery])

    const getSearchedUsers = async () => {
        if (searchQuery.length > 3) {
            const res = await axios.get(process.env.REACT_APP_SERVER_API + '/users?search=' + searchQuery, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (res.status === 200) {
                setUser(res?.data?.data?.user)
                if (res.data.data?.user == null)
                    setProductNotFound(true)
            }
        }
        else {
            getUser()
        }
    }

    useEffect(() => {
        if (windowSize.innerWidth < 1008 || windowSize.innerHeight < 900) {
            setMobileScreenStatus(true)
        } else {
            setMobileScreenStatus(false)
        }
    }, [windowSize])

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const [scrollClientHeight, setScrollClientHeight] = useState()
    const onScroll = (e) => {
        setScrollValue(e.target.documentElement.scrollTop);
        setScrollClientHeight(e.target.documentElement.scrollHeight - e.target.documentElement.clientHeight)
    };
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        if (mobileScreenStatus) {
            if ((scrollValue * 100) / scrollClientHeight > 95) {
                if (user?.length < totalCount) {
                    var start = page
                    start = start + 1
                    setPage(start)
                    setHasMoreRecords(true)
                    getPaginatedUsers()
                }
                else
                    setHasMoreRecords(false)
            }
        }
        else if (scrollValue === scrollClientHeight) {
            if (user?.length <= totalCount) {
                var start = page
                start = start + 1
                setPage(start)
                setHasMoreRecords(true)
                getPaginatedUsers()
            }
            else
                setHasMoreRecords(false)
        }
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollValue]);

    return (
        <div ref={ref}>
            <Header />
            <Modal open={open} disableBackdropClick={true} onClose={handleClose} className="overflow-y-scroll ml-5">
                <div className="container mx-auto">
                    <div className="flex justify-center m-0 p-0 md:mt-20 h-full">
                        <div className="w-full flex">
                            <div
                                className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover bg-no-repeat rounded-l-lg"
                                style={{ backgroundImage: `url(https://wall-empire.s3.ap-south-1.amazonaws.com/Other_Image/02+Nature+Wallpaper.jpg)` }}
                            ></div>
                            <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                                <div className="flex justify-end">
                                    <X
                                        onClick={() => handleClose()}
                                        className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
                                    />
                                </div>
                                <h3 className="pt-4 text-2xl text-center font-semibold">
                                    Create User
                                </h3>
                                <form onSubmit={e => { handleSubmit(e) }}>
                                    <div>
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Role</div>
                                        <select className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='username' autoComplete='off' value={role} onChange={rolehandleChange} type="text" placeholder="Enter your username" required>
                                            <option value="admin" className='m-5'>Admin</option>
                                            <option value="user">User</option>
                                        </select>
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Username <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='username' autoComplete='off' value={username} onChange={usernamehandleChange} type="text" placeholder="Enter your username" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Company Name <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='company' value={company} onChange={companyhandleChange} type="text" placeholder="Enter your company name" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">GST Number <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='company' value={gst} autoComplete='off' onChange={gsthandleChange} type="number" placeholder="Enter your GST number" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Phone Number <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='phone' value={phone} onChange={phonehandleChange} type="number" placeholder="1234567890" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Email Address <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='email' value={email} onChange={emailhandleChange} type="email" placeholder="xxx@gmail.com" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">Password <span className="text-red-600">*</span></div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='password' value={password} onChange={passwordhandleChange} type="password" placeholder="Enter your password" required />
                                    </div>
                                    <div className="mt-4">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide flex justify-between"><label>Confirm Password <span className="text-red-600">*</span></label>
                                            {
                                                !validatePassword ?
                                                    <div className=" text-red-500">Confirm password is not matching</div>
                                                    : null
                                            }
                                        </div>
                                        <input className="w-full text-lg p-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500 rounded-md" name='confirmPassword' value={confirmPassword} onChange={confirmPasswordhandleChange} type="password" placeholder="Enter your confirm password" required />
                                    </div>
                                    <div className="mt-4">
                                        <button type='submit' className="bg-yellow-500 float-right text-gray-100 p-3 w-80 rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg">
                                            Register
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="pl-4 pr-4 pt-32 bg-gray-200 min-h-screen">
                <div className="mb-1">
                    <div className="font-extrabold text-2xl text-center"> USER SECTION</div>
                    <div className="text-center">Creation And Modification Part Of User</div>
                </div>

                <div className="sticky top-24 bg-gray-200 pt-4 z-10 grid grid-cols-1 md:grid-cols-2 gap-4 pl-5">
                    <div className="flex items-center w-full md:w-96 pr-6">
                        <label for="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="search" autoComplete='off' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                        <button type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-yellow-500 rounded-lg border border-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-400 dark:bg-blue-600 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"><svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></button>
                    </div>
                    <div className="w-full flex justify-center md:justify-end pr-6 sm:pr-10">
                        <button className='p-4 pt-3 pb-3 rounded-xl w-full md:w-40 bg-yellow-500 hover:bg-yellow-600 text-white' onClick={() => handleOpen()}>Add User</button>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-5">
                    {user?.map((item, index) => {
                        return (
                            <div key={index} className="rounded-xl shadow-2xl bg-gray-200 overflow-hidden divide-black" data-aos="fade-up">
                                <div className="overflow-hidden pb-2 pt-5 justify-center flex">
                                    <div className='w-28 h-28 rounded-full bg-gray-500 justify-center flex items-center text-5xl text-white'>
                                        {item.companyName[0].toUpperCase()}
                                    </div>
                                </div>

                                <div className="overflow-hidden justify-center flex">
                                    {item.companyName}
                                </div>

                                <div className="overflow-hidden pb-2 justify-center flex">
                                    {item.email}
                                </div>

                                <div className="grid grid-cols-2 divide-x divide-gray-400 pb-2">
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center font-semibold">
                                            USERNAME
                                        </div>
                                        <div className="w-full flex justify-center">
                                            {item.username}
                                        </div>
                                    </div>
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center font-semibold">
                                            PHONE
                                        </div>
                                        <div className="w-full flex justify-center">
                                            {item.phoneNumber}
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 divide-x divide-gray-400 pb-2">
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center">
                                            <Switch
                                                checked={item.active}
                                                onChange={(e) => active(item._id, e)}
                                                name={item.username}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center font-semibold">
                                            Active
                                        </div>
                                    </div>
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center">
                                            <Switch
                                                checked={item.ban}
                                                onChange={(e) => ban(item._id, e)}
                                                name={item.username}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center font-semibold">
                                            Block
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 divide-x divide-gray-400 pb-2">
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center">
                                            <Switch
                                                checked={item.isHome}
                                                onChange={(e) => isHome(item._id, e)}
                                                name={item.username}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center font-semibold">
                                            Is Home
                                        </div>
                                    </div>
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center">
                                            <Switch
                                                checked={item.isProduct}
                                                onChange={(e) => isProduct(item._id, e)}
                                                name={item.username}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center font-semibold">
                                            Is Product
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 divide-x divide-gray-400 pb-2">
                                    <div className="pl-2 overflow-hidden">
                                        <div className="w-full flex justify-center">
                                            <Switch
                                                checked={item.isEnquiry}
                                                onChange={(e) => isEnquiry(item._id, e)}
                                                name={item.username}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center font-semibold">
                                            Is Inquiry
                                        </div>
                                    </div>
                                </div>
                                {
                                    item.active === true ?
                                        <div className="bg-green-800 w-full text-white text-center">
                                            Active
                                        </div>
                                        :
                                        <div className="bg-red-800 w-full text-white text-center">
                                            Inactive
                                        </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {
                    productNotFound ?
                        <div className="text-center p-5 text-lg font-bold text-gray-400">
                            <h1>No User Found</h1>
                        </div>
                        : null
                }
                {
                    hasMoreRecords ?
                        <div className="flex justify-center items-center">
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-yellow-300 mb-4" role="status">
                            </div>
                        </div>
                        : null
                }
            </div>
            <Footer />
        </div >
    )
}
