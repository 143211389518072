import React, { useEffect, useState, useLayoutEffect } from 'react'
import Header from '../components/Header.js';
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer.js';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion"
import Logo from '../resources/images/wall empire.png';
import FadeCarousel from '../components/Animation/FadeCarousel.js';
import { fadeIn } from 'react-animations'
import Radium from 'radium';
import axios from '../config/api';
import { X } from 'heroicons-react';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function Home() {
    const [newArrival1Data, setNewArrical1Data] = useState([])
    const [newArrival2Data, setNewArrical2Data] = useState([])
    const [topSellingData, setTopSellingData] = useState([])
    const [customerDiariesData, setCustomerDiariesData] = useState([])
    const [adsData, setAdsData] = useState([])
    const [showSubscribe, setShowSubscribe] = useState(false)
    const [videoAdsData, setVideoAdsData] = useState([])
    const [model, setmodel] = useState(true);
    const showPopup = useState(JSON.parse(localStorage.getItem("showPopup")));
    const [popupData, setPopupData] = useState({});
    const [userData] = useState(JSON.parse(localStorage.getItem("user")));

    const styles = {
        fadeIn: {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn'),
        }
    }

    const authenticate = () => {
        return new Promise(resolve => setTimeout(resolve, 2000))
    }

    const getNewArrivals = async () => {
        const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=NewArrivals", {
            headers: {
                authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
            }
        })
        if (bannerRes.status === 200) {
            const Arrival1 = []
            const Arrival2 = []
            bannerRes.data.data.map((img, index) => {
                if ((Math.ceil(bannerRes.data.data.length / 2) - 1) < index) {
                    Arrival1.push({ "image": img.image, "thumbnail": img.thumbnail, "type": "NewArrivals", "productId": img.productId })
                }
                else {
                    Arrival2.push({ "image": img.image, "thumbnail": img.thumbnail, "type": "NewArrivals", "productId": img.productId })
                }
            })
            setNewArrical1Data(Arrival1)
            setNewArrical2Data(Arrival2)
        }
    }

    const getPopUpOffer = async () => {
        let res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/popOffer`,
            {
                headers: {
                    authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                },
            }
        );
        setPopupData(res?.data.data.popOffer[0])
    }

    const getTopSelling = async () => {
        const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=TopSelling", {
            headers: {
                authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
            }
        })
        if (bannerRes.status === 200) {
            const TopSelling = []
            bannerRes.data.data.map((img) => {
                TopSelling.push({ "image": img.image, "thumbnail": img.thumbnail, "order": img.order, "type": "TopSelling" })
            })
            setTopSellingData(TopSelling.sort((a, b) => a.order - b.order))
        }
    }

    const getCustomerDiaries = async () => {
        const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=CustomerDiaries", {
            headers: {
                authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
            }
        })
        if (bannerRes.status === 200) {
            setCustomerDiariesData(bannerRes.data.data.sort((a, b) => a.order - b.order))
        }
    }

    const getAds = async () => {
        const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=Ads", {
            headers: {
                authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
            }
        })
        if (bannerRes.status === 200) {
            const Ads = []
            const VideoAds = []
            bannerRes.data.data.map((img) => {
                if (img.mediaType !== 'video') {
                    Ads.push({ "image": img.image, "thumbnail": img.thumbnail, "order": img.order, "type": "Ads" })
                } else {
                    VideoAds.push({ "src": img.image, type: 'video/mp4' })
                }
            })
            setVideoAdsData(VideoAds)
            setAdsData(Ads.sort((a, b) => a.order - b.order))
        }
    }

    useLayoutEffect(() => {
        if (userData == null)
            localStorage.setItem('_token', 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl')
        pageProcess()
    }, [])

    const pageProcess = async () => {
        getNewArrivals()
        getTopSelling()
        getCustomerDiaries()
        getAds()
        getPopUpOffer()
    }

    useEffect(() => {
        authenticate().then(() => {
            const ele = document.getElementById('progress-indicator')
            if (ele) {
                ele.classList.add('available')
                setTimeout(() => {
                    // ele.outerHTML = ''
                }, 2000)
            }
        })
    }, [])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const handleClose = () => {
        setmodel(false);
        setShowSubscribe(false);
        localStorage.setItem('showPopup', JSON.stringify(false));
    };

    useEffect(() => {
        if (showPopup[0]) {
            setTimeout(() => setShowSubscribe(true), 8000);
        }
    }, []);

    const handlePopup = () => {
        if (showPopup[0] == null) {
            localStorage.setItem('showPopup', JSON.stringify(true))
        }
    }

    return (
        <motion.div exit={{ opacity: 0 }} onLoad={handlePopup()}>
            <Header />
            {
                showSubscribe ?
                    <div className={model ? "discount-model open pt-0" : "discount-model"} style={styles.fadeIn} data-aos="fade-in">
                        <div className="bg-white bg-contain w-4/5 md:w-2/3 lg:w-2/5 h-2/4 md:h-1/2 lg:h-1/2 text-center my-auto rounded-lg opacity-100">
                            <div className='m-auto'>
                                <div className='flex justify-end'>
                                    <X onClick={handleClose} className=" text-black m-2 rounded-full " />
                                </div>
                                <div className=''>
                                    <ProgressiveImage src={popupData?.image}>
                                        {(src) => (
                                            <img src={src} alt="" />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                {/* <div className="mt-10 text-lg" style={{ fontFamily: "Copperplate Gothic Light" }}><p>First Time Here ?</p></div>

                                <div><h2 className='text-4xl my-5' style={{ fontFamily: "Rockwell" }}> Get 15% OFF</h2></div>

                                <div>
                                    <input className="bg-white text-sm leading-none text-gray-800 p-2 w-4/5 lg:w-3/5 border border-black focus:outline-none focus:border-gray-500 placeholder-black rounded" type="email" placeholder="Enter Your Email Address" />
                                </div>

                                <div>
                                    <button className="lg:w-3/5 w-4/5 mt-3 hover:bg-gray-900 bg-gray-800 rounded text-sm font-medium leading-none text-white p-3 uppercase focus:outline-none"> SEND ME A COUPON</button>

                                </div>

                                <div className="mt-3 text-gray-700" style={{ fontFamily: "Bahnschrift SemiBold" }}>*This One Time Offer is for New Customers Only </div> */}
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className='pt-20 md:pt-20 lg:pt-0'>
                <Banner className="h-80 sm:h-80 md:h-1/2 lg:h-screen 2xl:h-screen w-full" />
            </div>
            <main className="home select-none max-w-7xl mx-auto px-8 sm:px- ">
                {/* New arrival section */}
                <section className="pt-20">
                    <div className='w-full md:w-9/12 mb-5'>
                        <h1 className='font-bold md:text-3xl text-2xl'>New Arrival </h1>
                        <span className='text-gray-500 md:text-lg text-base text-justify'></span>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-5 items-center">
                        <div id="newArrival1" data-aos="fade-right">
                            <div className="relative lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg cursor-pointer">
                                {newArrival1Data.length !== 0 ? <FadeCarousel
                                    images={newArrival1Data}
                                    imageStyle={'lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg'}
                                /> : null}
                            </div>
                        </div>

                        <div id="newArrival2" data-aos="fade-left">
                            <div className="relative lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg cursor-pointer">
                                {newArrival2Data.length !== 0 ? <FadeCarousel
                                    images={newArrival2Data}
                                    imageStyle={'lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg'}
                                /> : null}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Top selling section */}
                <section className="pt-20" data-aos="fade-left">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 items-center">
                        <div className='md:w-8/12'>
                            <h1 className='font-bold md:text-3xl text-2xl'>Top Selling This Month</h1>
                        </div>
                        <div id="topSelling">
                            <div className="relative lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg" >
                                {topSellingData.length !== 0 ? <FadeCarousel
                                    images={topSellingData}
                                    imageStyle={'lg:h-96 md:h-54 sm:h-60 h-48 w-full rounded-lg'}
                                /> : null}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Ads Video */}
                {videoAdsData.length !== 0 ?
                    <section className="pt-20" data-aos="zoom-in">
                        <div className='w-full md:w-9/12 mb-5'>
                            <div className='font-bold md:text-3xl text-2xl'></div>
                            <span className='text-gray-500 md:text-lg text-base text-justify'></span>
                        </div>

                        <div className='w-full mb-5'>
                            <div className="relative w-full rounded-lg cursor-pointer">
                                <video
                                    playsInline
                                    loop
                                    autoPlay
                                    muted
                                    controls
                                    className="active"
                                    alt="All the devices"
                                    src={videoAdsData.map(item => {
                                        return item.src
                                    })}
                                />
                            </div>
                        </div>
                    </section>
                    : null}

                {/* Why us section */}
                <section className="pt-20">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 items-center">
                        <div className='md:w-8/12 flex justify-start'>
                            <h1 className='font-bold text-3xl'>Why us?</h1>
                        </div>
                        <div className='flex justify-center mt-5 md:justify-end'>
                            {/* <button className="bg-yellow-500 text-gray-100 p-4 w-1/2 rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg">
                                    BOOK A CONSULTATION
                                </button> */}
                        </div>
                    </div>
                    <div className="grid md:grid-cols-9 grid-cols-2 gap-4 pt-10 text-center items-center relative">
                        <div className='col-span-4'>
                            <div className='flex justify-center shadow-2xl md:shadow-none md:justify-end pt-10 pb-10' data-aos="fade-up-left" data-aos-duration="2000">
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/Innovative+technology.png" className='w-20 h-20 mx-auto' alt='Innovative technology' />
                                    <span className='text-base'>Innovative technology</span>
                                </div>
                            </div>
                            <div className='flex justify-center shadow-2xl md:shadow-none md:justify-center pt-10 pb-10' data-aos="fade-left" data-aos-duration="2000">
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/superior+quality.png" className='w-20 h-20 mx-auto' alt='Superior quality' />
                                    <span className='text-base'>Superior quality</span>
                                </div>
                            </div>
                            <div className='flex justify-center shadow-2xl md:shadow-none md:justify-end pt-10 pb-10' data-aos="fade-down-left" data-aos-duration="2000">
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/Experienced+team.png" className='w-20 h-20 mx-auto' alt='Experienced team' />
                                    <span className='text-base'>Experienced team</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                className="h-48 invisible md:visible w-auto absolute -translate-y-1/2 -translate-x-1/4 transform"
                                src={Logo}
                                alt="wallempire logo"
                            />
                        </div>
                        <div className='col-span-4' data-aos="fade-up-right" data-aos-duration="2000">
                            <div className='flex justify-center shadow-2xl md:shadow-none md:justify-start pt-10 pb-10'>
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/assurance.png" className='w-20 h-20 mx-auto' alt='Quality assurance' />
                                    <span className='text-base'>Quality assurance</span>
                                </div>
                            </div>
                            <div className='flex justify-center shadow-2xl md:shadow-none pt-10 pb-10' data-aos="fade-right" data-aos-duration="2000">
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/production.png" className='w-20 h-20 mx-auto' alt='Efficient production line' />
                                    <span className='text-base'>Efficient production line</span>
                                </div>
                            </div>
                            <div className='flex justify-center shadow-2xl md:shadow-none md:justify-start pt-10 pb-10' data-aos="fade-down-right" data-aos-duration="2000">
                                <div>
                                    <img src="https://wall-empire.s3.ap-south-1.amazonaws.com/Icons/Product+sampling.png" className='w-20 h-20 mx-auto' alt='Product sampling' />
                                    <span className='text-base'>Product sampling</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Customer Diaries section */}
                <section className="pt-20">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <div className='md:w-8/12'>
                            <h1 className='font-bold md:text-3xl text-2xl flex justify-start'>Customer Diaries</h1>
                        </div>
                        <div className='flex justify-center mt-5 md:justify-end'>
                        </div>
                    </div>
                    <Carousel responsive={responsive} autoPlay infinite className='pt-10 z-0'>
                        {
                            customerDiariesData?.map((item, index) => {
                                return (
                                    <div className="flex items-center mr-5" key={index} data-aos="fade-left"
                                        data-aos-duration={500 * (index + 1)}>
                                        <figure className="relative sm:max-w-xs h-96 w-full">
                                            <ProgressiveImage src={item?.image}>
                                                {(src) => (
                                                    <img alt={item?.city}
                                                        className={`rounded-lg shadow-xl w-full hover:shadow-2xl h-96 object-cover`}
                                                        src={src}
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div className="absolute text-lg -mt-20 w-full text-white px-4 h-20 bg-gradient-to-b from-transparent to-black">
                                                <div>
                                                    <h1>{item?.city}</h1>
                                                </div>
                                            </div>
                                        </figure>
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </section>

                {/* Ads section */}
                <section className="pt-5 pb-10" data-aos="zoom-out">
                    <div
                        id="productImages"
                        className="carousel relative"
                        data-bs-ride="carousel">
                        <div className="relative md:h-128 sm:h-60 h-48 w-full rounded-lg">
                            {adsData.length !== 0 ? <FadeCarousel
                                images={adsData}
                                imageStyle={'md:h-128 sm:h-60 h-48 w-full rounded-lg'}
                            /> : null
                            }
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </motion.div>
    )

}