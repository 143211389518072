import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/api';
import Swal from 'sweetalert2';

export default function ForgotPassword() {
    const [email, setEmail] = useState("")
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(false)

    const handleEmail = (val) => {
        setEmail(val)
    }
    const handleSubmit = async (e) => {
        if (email != "") {
            const res = await axios.post(process.env.REACT_APP_SERVER_API + "/verifyEmailUser", {
                email: email,
                url: `${window.location.origin}/changepassword`
            }, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (res.status == 200) {
                setEmailError(false)
                Swal.fire({
                    html: 'Password Reset Link Sent Successfully',
                    timer: 1000,
                    position: 'top-end',
                    icon: 'success',
                    timerProgressBar: true,
                    showConfirmButton: false
                })
            }
            else {
                setEmailError(true)
            }
        }
        e.preventDefault();
    }
    
    return (
        <div>
            <Header />
            <div className="h-screen mx-auto py-24">
                <div className="flex justify-center">
                    <div className="w-full md:pt-12 xl:w-3/4 lg:w-11/12 flex md:my-32">
                        <div className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg" style={{ backgroundImage: "url('https://wall-empire.s3.ap-south-1.amazonaws.com/Other_Image/06+Zebra+Blind.jpg')" }}></div>
                        <div className="w-full lg:w-1/2 bg-white p-12 rounded-lg lg:rounded-l-none">
                            <div className="px-8 mb-4 text-center">
                                <h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
                                <p className="mb-4 text-sm text-gray-700">
                                    We get it, stuff happens. Just enter your email address below and we'll send you a
                                    link to reset your password!
                                </p>
                            </div>
                            <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                                <div>
                                    <form action="">
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-bold text-gray-700" for="email">
                                                Email
                                            </label>
                                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" autoComplete='off' id="email" type="email" required placeholder="Enter Email Address..." onChange={(e) => handleEmail(e.target.value)}
                                            />
                                        </div>
                                        {
                                            emailError ?
                                                <div className="mb-4">
                                                    <label className="block mb-2 text-sm font-bold text-red-600" for="emailError">
                                                        Email address not found.
                                                    </label>
                                                </div>
                                                : null
                                        }
                                        <div className="mb-6 text-center">
                                            <button
                                                className="w-full px-4 py-2 font-bold text-white bg-yellow-500 rounded-full hover:bg-yellow-400 focus:outline-none focus:shadow-outline"
                                                type="button" onClick={(e) => handleSubmit(e)}
                                            >
                                                Send Reset Password Link
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}