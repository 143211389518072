import React, { useState, useLayoutEffect } from 'react'
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import axios from '../config/api';
import { useNavigate } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import S3 from 'aws-sdk/clients/s3'
import { randomBytes } from 'crypto'
import { X } from "heroicons-react";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ProgressiveImage from 'react-progressive-graceful-image';
import imageCompression from 'browser-image-compression';

export default function ManageAds() {
    const navigate = useNavigate();
    const [bannerData, setBannerData] = useState([])
    const [open, setOpen] = React.useState(false);
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY

    const [bannerType, setBannerType] = useState('Ads')
    const [bannerUrl, setBannerUrl] = useState()
    const [bannerThumbnailUrl, setBannerThumbnailUrl] = useState()
    const [bannerOrder, setBannerOrder] = useState(0)
    const [imgUploaded, setImgUploaded] = useState(false)
    const [maxBananer, setMaxBananer] = useState(false)

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    })

    const [postImage, setpostImage] = useState('');
    const [mediaType, setmediaType] = useState('');
    const [userData] = useState(JSON.parse(localStorage.getItem('user')));

    const getAllBanners = async () => {
        if (userData == null || userData?.user?.role == 'user') {
            navigate('/')
        }
        else if (!userData?.user?.isHome) {
            navigate('/')
        } else {
            const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=Ads", {
                headers: {
                    authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
                }
            })
            if (bannerRes.status === 200) {
                const Ads = []
                setBannerOrder(bannerRes.data.data.length + 1)
                bannerRes.data.data.map((banner) => {
                    if (banner.mediaType != 'video') {
                        Ads.push(banner)
                    }
                })
                setBannerData(Ads.sort((a, b) => a.order - b.order))
                if (bannerRes.data.data.length >= 10)
                    setMaxBananer(true)
            }
        }
    }

    const selectedType = (e) => {
        setBannerType(e.target.value)
    }

    const handleBannerOrder = (e) => {
        setBannerOrder(e.target.value)
    }

    useLayoutEffect(() => {
        pageProcess()
    }, [])

    const pageProcess = () => {
        getAllBanners()
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        axios.post(process.env.REACT_APP_SERVER_API + '/banner',
            {
                'type': bannerType,
                'bannerImage': bannerUrl,
                'thumbnail': bannerThumbnailUrl,
                'order': Number(bannerOrder),
                'mediaType': mediaType
            }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        })
            .then(function (response) {
                handleClose()
                getAllBanners()
                setpostImage('')
                setBannerUrl('')
                setImgUploaded(false)
            })
            .catch(function (error) {
            });
        e.preventDefault();
    }

    const updateBanners = async (item, e) => {
        for (let index = 0; index < item.length; index++) {
            const element = item[index];
            await axios.patch(process.env.REACT_APP_SERVER_API + '/banner', {
                bannerId: element._id,
                order: index + 1,
            }, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            });
        }
        getAllBanners()
    }

    const handlepostImage = async (e) => {
        const [file] = e.target.files;
        if (e.target.files[0].type == 'video/mp4') {
            setmediaType('video')
        } else {
            setmediaType('image')
        }

        const options = {
            maxSizeMB: 0.05,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }

        try {
            setpostImage(URL.createObjectURL(file));
            imageUpload(e.target.files[0], "", (url) => {
                setBannerUrl(url)
                setImgUploaded(true)
            })
            const compressedFile = await imageCompression(file, options);
            imageUpload(compressedFile, "-min", (url) => {
                setBannerThumbnailUrl(url)
                setImgUploaded(true)
            })
        } catch (error) {
            console.log(error);
        }
    };

    const imageUpload = async (imageFile, type, callback) => {
        const rawBytes = await randomBytes(16)
        const imageName = rawBytes.toString('hex')

        const uploadURL = await s3.getSignedUrlPromise('putObject', {
            Bucket: bucketName,
            Key: "home/Ads/" + imageName + type,
            Expires: 60
        })

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: imageFile
        })

        const imageUrl = uploadURL.split('?')[0]
        callback(imageUrl)
    };

    const handleDelete = async (banner) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                imageDelete(banner.image.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))
                imageDelete(banner.thumbnail.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))

                const bannerRes = await axios.delete(process.env.REACT_APP_SERVER_API + `/banner?bannerId=${banner._id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })
                if (bannerRes.status === 200) {
                    getAllBanners()
                    ShowRecordAddedAlert()
                }
                Swal.fire(
                    {
                        title: 'Deleted!',
                        text: 'Your Ad has been deleted.',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    }
                )
            }
        })
    }

    const imageDelete = (imageFile) => {
        s3.deleteObject({
            Bucket: bucketName,
            Key: imageFile
        }, function (err, data) {
        })
    }
    const ShowRecordAddedAlert = () => {
        Swal.fire({
            position: 'bottom',
            customClass: 'mb-10 h-12',
            title: '<div className="flex ml-2 mt-1 p-1 items-center"><div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg bg-green-400 text-black mr-1"> <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="20px" height="20px"><path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"/></svg><span className="sr-only">Warning icon</span></div><span style="font-size:16px;" className="ml-2">Product deleted successful</span></div>',
            showConfirmButton: false,
            timer: 1000
        });
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let newBanners = Array.from(bannerData);
        let [source_data] = newBanners.splice(e.source.index, 1);
        newBanners.splice(e.destination.index, 0, source_data);
        setBannerData(newBanners);
        updateBanners(newBanners)
    };

    return (
        <div className='h-screen'>
            <Header />
            <Modal
                className='w-full flex justify-center'
                open={open}
                onClose={handleClose}
            >
                <div className='bg-white m-0 md:m-10 p-10 w-full md:w-3/4 rounded-xl overflow-auto'>
                    <div className="flex justify-between items-center mb-10">
                        <div className="mr-4">
                            <h1 className="font-weight-bold text-3xl">Banner Uploading</h1>
                        </div>
                        <X
                            onClick={() => handleClose()}
                            className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-4 pb-5">
                        <form onSubmit={e => { handleSubmit(e) }} id="hook-form" className="col-span-3">
                            <div className='w-full md:flex text-base'>
                                <div className='w-full mr-5'>
                                    <label>Category</label>
                                    <select name="type" className="rounded-md border-2 w-full text-base py-2 border-b border-gray-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 text-center" onChange={(e) => selectedType(e)}>
                                        <option value="" className='p-2'>Select Banner Type</option>
                                        <option value="Ads" className='p-2'>Ads</option>
                                    </select>
                                </div>
                                <div className='w-full mt-2 md:mt-0'>
                                    <label>Banner Display Order</label>
                                    <input type="text" defaultValue={bannerOrder} name="example" className="rounded-md border-2 w-full text-base py-1 border-b border-gray-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 text-center" autoComplete='off' placeholder='Ex: 1' onChange={(e) => handleBannerOrder(e)} />
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        imgUploaded ?
                            <div className="flex justify-center items-center w-full">
                                {mediaType == 'video' ?
                                    <video width="400px" height="200px" controls>
                                        <source src={postImage} type="video/mp4" />
                                    </video>
                                    :
                                    <ProgressiveImage src={postImage}>
                                        {(src) => (
                                            <img src={src} alt="" width="400px" height="200px" />
                                        )}
                                    </ProgressiveImage>
                                }
                            </div>
                            :
                            <div className="flex justify-center items-center w-full">
                                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or (MAX. 800 x 400px)</p>
                                    </div>
                                    <input id="dropzone-file" type="file" onChange={handlepostImage} className="hidden" />
                                </label>
                            </div>
                    }
                    <div className="mt-10 w-full md:w-1/4">
                        <button form="hook-form" type="submit" disabled={!imgUploaded} className={`${!imgUploaded ? "bg-gray-300 cursor-not-allowed" : "bg-yellow-500 hover:bg-yellow-600 "} text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline shadow-lg`}>
                            Add Ads
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="max-w-full mx-auto pt-24 px-6 min-h-screen">
                <div className="w-full flex justify-center md:justify-end py-6 sm:pr-10">
                    <button className={maxBananer ? 'p-4 pt-3 pb-3 cursor-default rounded-xl w-full md:w-40 bg-gray-300 text-black' : 'p-4 pt-3 pb-3 rounded-xl w-full md:w-40 bg-yellow-500 hover:bg-yellow-600 text-white'} onClick={handleOpen} disabled={maxBananer}>Add Ads</button>
                </div>
                <div className="flex flex-col">
                    <h1 className='text-sm text-gray-500'>Main banner image size must be <b>1216 × 640 px</b></h1>
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <div className="inline-block min-w-full align-middle">
                            <h1 className='text-sm text-red-500'>* Maximum 10 banners allowed</h1>
                            <div className="overflow-hidden ">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <table className="table borderd min-w-full">
                                        <thead>
                                            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                                <th className="py-3 px-6 text-left"></th>
                                                <th className="py-3 px-6 text-left">Banner Type</th>
                                                <th className="py-3 px-6 text-left">Image</th>
                                                <th className="py-3 px-6 text-left">Display Order</th>
                                                <th className="py-3 px-6 text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                >
                                                    {bannerData?.map((data, index) => (
                                                        <Draggable
                                                            key={data._id}
                                                            draggableId={data._id}
                                                            index={index}
                                                        >
                                                            {(provider) => (
                                                                <tr key={data._id} {...provider.draggableProps} ref={provider.innerRef} className="border-b border-gray-200 hover:bg-gray-100">
                                                                    <td {...provider.dragHandleProps} className="text-center w-10">
                                                                        <svg width="32px" className='inline-block' height="32px" viewBox="0 0 32 40" id="icon" xmlns="http://www.w3.org/2000/svg"><defs></defs><title>drag--vertical</title><polygon points="4 20 15 20 15 26.17 12.41 23.59 11 25 16 30 21 25 19.59 23.59 17 26.17 17 20 28 20 28 18 4 18 4 20" /><polygon points="11 7 12.41 8.41 15 5.83 15 12 4 12 4 14 28 14 28 12 17 12 17 5.83 19.59 8.41 21 7 16 2 11 7" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill='none' width="32" height="32" /></svg>
                                                                    </td>
                                                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            {data?.type}
                                                                        </div>
                                                                    </td>
                                                                    <td className="py-3 px-6 text-center">
                                                                        <ProgressiveImage src={data?.image}>
                                                                            {(src) => (
                                                                                <img src={src} alt="" width="100px" height="100px" />
                                                                            )}
                                                                        </ProgressiveImage>
                                                                    </td>
                                                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            {data?.order}
                                                                        </div>
                                                                    </td>
                                                                    <td className="py-3 px-6 text-center">
                                                                        <div className="flex item-center justify-center">
                                                                            <button className="w-4 transform hover:text-yellow-400 hover:scale-110 cursor-pointer" onClick={() => handleDelete(data)}>
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" stroke='currentColor' width="20px" height="20px"><path d="M 20.5 4 A 1.50015 1.50015 0 0 0 19.066406 6 L 14.640625 6 C 12.803372 6 11.082924 6.9194511 10.064453 8.4492188 L 7.6972656 12 L 7.5 12 A 1.50015 1.50015 0 1 0 7.5 15 L 8.2636719 15 A 1.50015 1.50015 0 0 0 8.6523438 15.007812 L 11.125 38.085938 C 11.423352 40.868277 13.795836 43 16.59375 43 L 31.404297 43 C 34.202211 43 36.574695 40.868277 36.873047 38.085938 L 39.347656 15.007812 A 1.50015 1.50015 0 0 0 39.728516 15 L 40.5 15 A 1.50015 1.50015 0 1 0 40.5 12 L 40.302734 12 L 37.935547 8.4492188 C 36.916254 6.9202798 35.196001 6 33.359375 6 L 28.933594 6 A 1.50015 1.50015 0 0 0 27.5 4 L 20.5 4 z M 14.640625 9 L 33.359375 9 C 34.196749 9 34.974746 9.4162203 35.439453 10.113281 L 36.697266 12 L 11.302734 12 L 12.560547 10.113281 A 1.50015 1.50015 0 0 0 12.5625 10.111328 C 13.025982 9.4151428 13.801878 9 14.640625 9 z M 11.669922 15 L 36.330078 15 L 33.890625 37.765625 C 33.752977 39.049286 32.694383 40 31.404297 40 L 16.59375 40 C 15.303664 40 14.247023 39.049286 14.109375 37.765625 L 11.669922 15 z" /></svg>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}