import Footer from "../components/Footer";
import Header from "../components/Header";
import { StyleRoot } from "radium";
import React, { useState, useEffect, moment } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { X } from "heroicons-react";
import Swal from 'sweetalert2';

export default function ProductInquiry() {
    const [inquiries, setInquiries] = useState([]);
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [currentInquiry, setCurrentInquiry] = React.useState();
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset] = useState(20);
    const [pagination, setPagination] = useState([]);
    const [currentItems, setCurrentItems] = useState(null);
    let userData = JSON.parse(localStorage.getItem('user'));
    const getAllInquiries = async () => {
        if (userData == null || !userData?.user?.isEnquiry) {
            navigate('/')
        }
        else {
            let setInquiriesRes;
            if (userData?.user?.role?.toLowerCase() === "admin") {
                setInquiriesRes = await axios.get(
                    process.env.REACT_APP_SERVER_API + `/enquiry/product?page=` + pageCount + '&limit=' + itemOffset,
                    {
                        headers: {
                            authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                        },
                    }
                );
                if (setInquiriesRes.status === 200) {
                    paginationPages(setInquiriesRes.data.data.page)
                    setCurrentItems(setInquiriesRes.data.data.currentPage)
                    setInquiries(setInquiriesRes.data.data.enquiryProduct);
                }
            }
            else {
                setInquiriesRes = await axios.get(
                    process.env.REACT_APP_SERVER_API + `/enquiry/product?user_id=${userData.user._id}`,
                    {
                        headers: {
                            authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                        },
                    }
                );
                if (setInquiriesRes.status === 200) {
                    setInquiries(setInquiriesRes.data.data.enquiryProduct);
                }
            }
        }
    };

    useEffect(() => {
        pageProcess();
    }, [pageCount]);

    const pageProcess = async () => {
        setInquiries([]);
        await getAllInquiries();
    };

    const navigateToProductPage = (item) => {
        navigate(`/productdetails?id=${item.product_id}&category=main&f=all`)
    }

    const handleOpen = (item) => {
        setCurrentInquiry(item)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const paginationPages = (totalPage) => {
        var count = []
        for (var i = 0; i < totalPage; i++) {
            count.push(i)
        }
        setPagination(count);
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await axios.delete(process.env.REACT_APP_SERVER_API + `/enquiry/product?id=${id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })

                if (res.status === 200) {
                    getAllInquiries()
                }

                Swal.fire(
                    {
                        title: 'Deleted',
                        text: "Your record has been deleted.",
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1000
                    }
                )
            }
        })
    }

    return (
        <StyleRoot>
            <Header />
            <Modal open={open} className="overflow-y-scroll" disableBackdropClick={true} onClose={handleClose}>
                <div className="container mx-auto">
                    <div className="flex justify-center m-0 p-0 md:mt-24 h-screen md:h-full">
                        <div className="w-full lg:w-full bg-white p-5 rounded-lg">
                            <div className="flex justify-end items-center">
                                <div className="mr-4">
                                    <button className="w-full rounded-md bg-gray-600 px-2 py-2 text-indigo-100 hover:bg-yellow-500 hover:shadow-md duration-75" onClick={() => navigateToProductPage(currentInquiry)}>View Product</button>
                                </div>
                                <X
                                    onClick={() => handleClose()}
                                    className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
                                />
                            </div>
                            <h3 className="pt-4 text-2xl text-center font-semibold">
                                Inquiry
                            </h3>
                            <div
                                className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
                            >
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-sm font-bold text-gray-700"
                                        for="fullName"
                                    >
                                        Product Name
                                    </label>
                                    <div
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    >
                                        {currentInquiry?.product_info[0].postName}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-sm font-bold text-gray-700"
                                        for="fullName"
                                    >
                                        Full Name
                                    </label>
                                    <div
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    >
                                        {currentInquiry?.name}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-sm font-bold text-gray-700"
                                        for="phone"
                                    >
                                        Phone Number
                                    </label>
                                    <div
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    >
                                        {currentInquiry?.contact_number}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-sm font-bold text-gray-700"
                                        for="email"
                                    >
                                        Email
                                    </label>
                                    <div
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    >
                                        {currentInquiry?.email}
                                    </div>
                                </div>
                                <div className="mb-4 md:flex md:justify-between">
                                    <div className="mb-4 md:mr-2 md:mb-0 w-2/6">
                                        <label
                                            className="block mb-2 text-sm font-bold text-gray-700"
                                            for="height"
                                        >Image Height
                                        </label>
                                        <div
                                            className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        >
                                            {currentInquiry?.height}
                                        </div>
                                    </div>
                                    <div className="mb-4 md:mr-2 md:mb-0 w-2/6">
                                        <label
                                            className="block mb-2 text-sm font-bold text-gray-700"
                                            for="width"
                                        >
                                            Image Width
                                        </label>
                                        <div
                                            className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        >
                                            {currentInquiry?.width}
                                        </div>
                                    </div>
                                    <div className="mb-4 md:mr-2 md:mb-0 w-2/6">
                                        <label
                                            className="block mb-2 text-sm font-bold text-gray-700"
                                            for="dimension"
                                        >
                                            Dimension
                                        </label>
                                        <div
                                            className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        >
                                            {currentInquiry?.dimension}
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-bold text-gray-700">
                                        Description
                                    </label>
                                    <div
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight resize-none text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    >
                                        {currentInquiry?.instruction}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="max-w-full mx-auto h-screen pt-32 px-6">
                <div className="mb-1 pb-6">
                    <div className="font-extrabold text-2xl text-center">Products Inquiries</div>
                </div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden ">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-left">Product Name</th>
                                            <th className="py-3 px-6 text-left">User name</th>
                                            <th className="py-3 px-6 text-center">Phone</th>
                                            <th className="py-3 px-6 text-center">Email</th>
                                            <th className="py-3 px-6 text-center">Created At</th>
                                            <th className="py-3 px-6 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light">
                                        {
                                            inquiries?.length === 0 ?
                                                <tr><td className="text-center text-base pt-4" colSpan={9}>No Inquiry Found</td></tr>
                                                : null
                                        }
                                        {inquiries?.map((item, index) => {
                                            return (
                                                <tr className="border-b border-gray-200 hover:bg-gray-100" key={index}>
                                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <span className="font-medium">{item.product_info[0].postName}</span>
                                                        </div>
                                                    </td>
                                                    <td className="py-3 px-6 text-left">
                                                        <span className="py-1 px-3 rounded-full">
                                                            {item.name}
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-6 text-center">
                                                        <span className="py-1 px-3 rounded-full">
                                                            {item.contact_number}
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-6 text-center">
                                                        <span className="py-1 px-3 rounded-full">
                                                            {item.email}
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-6 text-center">
                                                        <span className="py-1 px-3 rounded-full">
                                                            {item.createAt}
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-6 text-center">
                                                        <div className="flex item-center justify-center">
                                                            <div className="w-4 mr-5 transform hover:text-yellow-400 hover:scale-110">
                                                                <button type="button" className="inline-block rounded-full text-black hover:text-yellow-400 leading-normal uppercase shadow-md transform duration-200 hover:scale-105 hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-8 h-8 md:w-10 md:h-10 p-1 md:p-2.5" onClick={() => handleOpen(item)}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                                        />
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <div className="w-4 ml-4 transform hover:text-yellow-400 hover:scale-110">
                                                                <button type="button" className="inline-block rounded-full text-black hover:text-yellow-400 leading-normal uppercase shadow-md transform duration-200 hover:scale-105 hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-8 h-8 md:w-10 md:h-10 p-1 md:p-2.5" onClick={() => handleDelete(item._id)}>
                                                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="1 2 25 25" stroke="currentColor"><path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" /></svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <nav
                                    className="flex justify-between items-center py-4 px-6"
                                    aria-label="Table navigation"
                                >
                                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                        Showing{" "}
                                        <span className="font-semibold text-gray-900 dark:text-white">
                                            1-10
                                        </span>{" "}
                                        of{" "}
                                        <span className="font-semibold text-gray-900 dark:text-white">
                                            {inquiries.length}
                                        </span>
                                    </span>
                                    <div className="flex justify-end">
                                        <nav aria-label="Page navigation example">
                                            <ul className="flex list-style-none">
                                                {currentItems == 0 ? '' :
                                                    <li className="page-item">
                                                        <a
                                                            className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                                                            href="#" onClick={() => setPageCount(currentItems - 1)}
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    pagination.map((count, index) => (
                                                        <li className="page-item pagination mx-0.5" key={index}>
                                                            <a className={pageCount == index ?
                                                                "page-link relative block py-1.5 px-3 border-0 bg-blue-600 outline-none transition-all duration-300 rounded-full text-white hover:text-white hover:bg-blue-600 shadow-md focus:shadow-md"
                                                                : "page-link relative block py-1.5 px-3 border-0 outline-none transition-all duration-300 hover:bg-blue-600 rounded-full text-black hover:text-white"} href="#" onClick={() => setPageCount(count)}>{count + 1}
                                                            </a>
                                                        </li>
                                                    ))
                                                }

                                                {currentItems == pagination.length - 1 ? '' :
                                                    <li className="page-item">
                                                        <a className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none" href="#" onClick={() => setPageCount(currentItems + 1)}>
                                                            Next
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </StyleRoot>
    );
}
