import React, { useEffect, useRef } from "react";
import videojs from "video.js";

const VideoPlayer = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      console.log("props ----> ", props);

    //   videoElement.classList.add("vjs-big-play-centered");
      videoElement.style.objectFit = "cover";
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...props,
          autoplay: true,
          muted: true, // Muting the video increases the chance of autoplay working
          preload: "auto",
          playsinline: true,
        },
        () => {
          console.log("player is ready");
          // When the player is ready, seek to 0 seconds to start from the beginning
        }
      ));

      player.on("ended", () => {
        props?.onEnded();
      });

      playerRef.current.play();
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      props?.onReady(player);
      player.autoplay(props.autoplay);
      player.src(props.sources);
      player.width(props.width);
      player.height(props.height);

      attemptAutoplay(player);

      player.on("ended", () => {
        props?.onEnded();
      });
    }
  }, [props]);

  const attemptAutoplay = (player) => {
    player.ready(() => {
      player.play().catch((error) => {
        console.log("Autoplay was prevented. Trying muted autoplay.");
        player.muted(true);
        player.play().catch((e) => {
          console.log(
            "Both autoplay attempts failed. User interaction required."
          );
          // Here you might want to show a prominent play button or other UI
          // to encourage user interaction.
        });
      });
    });
  };

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && props?.autoplay) {
      const playPromise = playerRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Autoplay started
          })
          .catch((error) => {
            // Autoplay was prevented
            console.log("Autoplay was prevented", error);
          });
      }
    }
  }, [props?.autoplay]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("webkit-playsinline", true);
      videoRef.current.setAttribute("playsinline", true);
    }
  }, []);

  return (
    <div data-vjs-player className={props?.className}>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;
