import axios from "axios";
import Swal from "sweetalert2";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
  // timeout: 30000
});

api.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(undefined, async function axiosRetryInterceptor(err) {
  var user = JSON.parse(localStorage.getItem('user'));
  if (user != null) {
    if (err?.response?.status === 409) {
      return err?.response;
    }
    if (err?.response?.status === 401) {
      axios.post(process.env.REACT_APP_SERVER_API + '/Auth/accessToken', {
        token: user.refresh_token
      }, {
        headers: {
          authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl"
        }
      }).then(function (res) {
        user['access_token'] = res.data.data.access_token
        user['refresh_token'] = res.data.data.refresh_token
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('_token', `Bearer ${res.data.data.access_token}`)
        Swal.fire({
          title: 'Something Went Wrong',
          text: "Something went wrong please try to reload the page.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Reload.'
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      }).catch(function (err) {
        localStorage.removeItem('user')
        window.location.replace("/")
      })
    }
  }
  return Promise.reject(err);
});
export default api;