import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver"
import XLSX from 'sheetjs-style'
import Excel from '../../resources/images/microsoft-excel.png'

export const ExportExcel = ({ catalog }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx"

    const exportToExcel = async () => {
        var excelData = []
        const catalogProductData = []
        var catalogData = {
            identification: "Catalog",
            name: catalog.name,
            url: catalog.URL
        }

        for (let i = 0; i < catalog?.catalogueProducts?.length; i++) {
            var product = {}
            product["identification"] = "Product"
            product["name"] = catalog?.catalogueProducts?.[i]?.name
            product = {
                ...product,
                ...catalog?.catalogueProducts?.[i]?.specification,
            }
            product["url"] = catalog?.catalogueProducts?.[i]?.URL
            catalogProductData.push(product)
        }
        excelData = [
            catalogData,
            ...catalogProductData
        ]

        const fileName = "test"
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return (
        <>
            <Button
                onClick={() => {
                    exportToExcel()
                }}
                style={{ cursor: "pointer", fontSize: 14, }}
            >
                <img src={Excel} className="w-10 m-auto cursor-pointer" alt="QR Code" />
            </Button>
        </>
    )
}