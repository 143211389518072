import Login from './pages/Login';
import Home from './pages/Home.js';
import Registration from './pages/Registration';
import ProductCategory from './pages/ProductCategory';
import ParentCategoryProduct from './pages/ParentCategoryProduct';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import User from './pages/User';
import Product from './pages/Product';
import FeatureProduct from './pages/FeatureProduct';
import Maintain from './pages/Maintain'
import TermsCondition from './pages/TermsCondition'
import PremiumProducts from './pages/PremiumProducts'
import ProductDetails from './pages/ProductDetails';
import ProductInquiry from './pages/ProductInquiry';
import UserInquiries from './pages/UserInquiries';
import ManageBanners from './pages/ManageBanners';
import ManageNewArrivals from './pages/ManageNewArrivals';
import ManageTopSelling from './pages/ManageTopSelling';
import ManageCustomerDiaries from './pages/ManageCustomerDiaries';
import ManageAds from './pages/ManageAds';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import DiscountOffer from './pages/DiscountOffer';
import Catalog from './pages/Catalog';
import SearchCatalog from './pages/SearchCatalog';
import CatalogDetails from './pages/CatalogDetails';
import AddCatalogue from './components/Catalog/AddCatalog';

var routers = []

if (process.env.REACT_APP_MODE === "true") {
    routers.push(
        { path: '/', element: <Home />, },
        { path: '/product-category/:subcategory', element: <ProductCategory />, },
        { path: '/category/:subcategory', element: <ParentCategoryProduct />, },
        { path: '/aboutus', element: <AboutUs />, },
        { path: '/contactus', element: <ContactUs />, },
        { path: '/signIn', element: <Login /> },
        { path: '/signUp', element: <Registration /> },
        { path: '/forgotpassword', element: <ForgotPassword /> },
        { path: '/admin/user', element: <User /> },
        { path: '/admin/product', element: <Product />, },
        { path: '/admin/feature-product', element: <FeatureProduct />, },
        { path: '/termsCondition', element: <TermsCondition />, },
        { path: '/premiumproducts', element: <PremiumProducts />, },
        { path: '/productdetails', element: <ProductDetails />, },
        { path: '/productinquiries', element: <ProductInquiry />, },
        { path: '/userinquiries', element: <UserInquiries />, },
        { path: '/managebanners', element: <ManageBanners />, },
        { path: '/managenewarrivals', element: <ManageNewArrivals />, },
        { path: '/managetopselling', element: <ManageTopSelling />, },
        { path: '/managecustomerdiaries', element: <ManageCustomerDiaries />, },
        { path: '/manageads', element: <ManageAds />, },
        { path: '/changepassword/:token', element: <ChangePassword />, },
        { path: '/discountoffer', element: <DiscountOffer />, },
        { path: '/catalog', element: <Catalog />, },
        { path: '/searchcatalog', element: <SearchCatalog />, },
        { path: '/catalogdetails', element: <CatalogDetails />, },
        { path: '/addcatalog', element: <AddCatalogue />, },
    )
} else {
    routers.push(
        { path: '/', element: <Maintain />, },
    )
}

export default routers;