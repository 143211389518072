import { ArrowCircleLeft } from 'heroicons-react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { useNavigate } from 'react-router-dom';

export default function Step3_ReviewCatalogue({ catalogueId, setVisible, catalog, products, hasNext, hasPrevious }) {
const navigate = useNavigate()
    return (
        <div className="w-full px-5">
            <button className='flex p-2 items-center text-md lg:w-auto leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none' onClick={() => navigate("/catalog")}>
                <ArrowCircleLeft
                    className=""
                /><span className='ml-2'>Back to Catalog</span>
            </button>
            <div className="flex items-center justify-center p-6">
                <div className="w-full">
                    <div className="mt-1 w-full">
                        <div className='md:flex'>
                            <div className="flex justify-center">
                                <div className="w-64 max-h-6w-64 rounded-2xl shadow-lg mb-4">
                                    <ProgressiveImage src={catalog?.image === "" ? "https://cdn.pixabay.com/photo/2021/01/15/17/01/green-5919790__340.jpg" : catalog?.image}>
                                        {(src) => (
                                            <img
                                                className="object-contain rounded-t-2xl rounded-b-none w-64 max-h-60"
                                                src={src}
                                                alt="img"
                                            />
                                        )}
                                    </ProgressiveImage>
                                    <div className="text-center mt-2 mb-2">
                                        <div>
                                            <label className="text-xs text-gray-400">Catalog Name</label><br /><label className="text-xl">{catalog?.name}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full justify-center items-center">
                                <label className="pl-5 text-lg">Product Details</label>
                                <div className='p-4'>
                                    <div className='grid grid-cols-5 gap-2 mt-1 max-w-full rounded-t-xl justify-center bg-gray-300 text-gray-600'>
                                        <div className='font-bold p-2'>
                                            Sr No
                                        </div>
                                        <div className='font-bold border-l-2 p-2'>
                                            Design Number
                                        </div>
                                        <div className='font-bold border-l-2 p-2'>
                                            Catalog Name
                                        </div>
                                        <div className='font-bold border-l-2 p-2'>
                                            Images
                                        </div>
                                        <div className='font-bold border-l-2 p-2'>
                                            360 Images
                                        </div>
                                    </div>
                                    <div className="max-h-60 bg-gray-100 rounded-b-lg overflow-auto">
                                        {
                                            products.map((item, index) => <div className='grid grid-cols-5 gap-2 w-full'>
                                                <div className='font-bold p-2'>
                                                    {index + 1}
                                                </div>
                                                <div className='border-l-2 p-2'>
                                                    {item?.name}
                                                </div>
                                                <div className='border-l-2 p-2'>
                                                    {catalog?.name}
                                                </div>
                                                <div className='border-l-2 p-2 grid grid-cols-5 gap-2 w-full'>
                                                    {item?.image?.map(imges => <img
                                                        className="object-cover w-full h-full"
                                                        src={imges}
                                                        alt="img"
                                                    />)}
                                                </div>
                                                <div className='border-l-2 p-2 grid grid-cols-5 gap-2 w-full'>
                                                    {item?.image360?.map(imges => <img
                                                        className="object-cover w-full h-full"
                                                        src={imges}
                                                        alt="img"
                                                    />)}
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <div className="flex md:justify-start md:w-1/2 w-full md:mr-2">
                            <button
                                className="px-6 py-2 mt-4 text-sm w-full lg:w-auto font-medium leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                onClick={() => hasPrevious()}
                            >
                                Goto Previous Step
                            </button>
                        </div>

                        <div className="flex md:justify-end md:w-1/2 w-full md:ml-2">
                            <button
                                className="px-6 py-2 mt-4 text-sm w-full lg:w-auto font-medium leading-5 text-center text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg hover:bg-yellow-600 focus:outline-none"
                                onClick={() => { hasNext(true) }}
                            >
                                {catalogueId === "" ? "Add Catalog To Your Collection" : "Update Your Catalog"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
