import React, { useEffect, useState } from 'react'
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { useNavigate } from 'react-router-dom';
import { StyleRoot } from "radium";
import QrCode from '../resources/images/qr-code.svg'
import { ExportExcel } from '../components/ExcelGenerator/Excel'
import axios from '../config/api';
import Swal from 'sweetalert2';

export default function Catalog() {
    const navigate = useNavigate();
    const [userData] = useState(JSON.parse(localStorage.getItem('user')));
    const [allCatalog, setAllCatalog] = useState([]);
    const [catalogProduct, setCatalogProduct] = useState([]);

    const getAllCatalog = async () => {
        if (userData == null) {
            navigate('/')
        }
        else {
            const res = await axios.get(
                process.env.REACT_APP_SERVER_API + `/catalogue`,
                {
                    headers: {
                        authorization: localStorage.getItem('_token'),
                    },
                }
            );
            if (res.status === 200) {
                setAllCatalog([
                    ...res.data.data.map((item) => {
                        item["expanded"] = false
                        return item
                    })
                ])
            } if (res.status === 204) {
                setAllCatalog([])
            }
        }
    };

    const pageProcess = async () => {
        setAllCatalog([]);
        await getAllCatalog();
    };

    useEffect(() => {
        pageProcess();
    }, []);

    const getQrCode = async (catalog) => {
        const perURL = process.env.REACT_APP_URL + "catalogdetails?CProductNumber="
        const CperURL = process.env.REACT_APP_URL + "catalogdetails?catalogueNumber="
        const url = process.env.REACT_APP_SERVER_API + `/catalogue-product/getQR?catalogueId=${catalog._id}&perURL=${`${perURL}&CperURL=${CperURL}`}`
        const res = await axios(url, {
            method: "GET",
            headers: {
                authorization: localStorage.getItem('_token'),
            },
        })
        if (res?.status === 200) {
            window.open(process.env.REACT_APP_SERVER_API + "/file?fileName=" + res?.data?.data?.fileName)
        }
    }

    const getCatalogProducts = async (id) => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/catalogue-product?catalogueId=${id}`,
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            }
        );
        if (res.status === 200) {
            if (res.data.data != null) {
                setCatalogProduct(res.data.data)
            }
        }
    }

    const getQRProducts = async (product) => {
        const url = process.env.REACT_APP_SERVER_API + `/catalogue-product/getQR?CProductId=${product._id}&perURL=${`${process.env.REACT_APP_URL}catalogdetails?CProductNumber=`}`
        const res = await axios(url, {
            method: "GET",
            headers: {
                authorization: localStorage.getItem('_token'),
            },
        })
        if (res?.status === 200) {
            window.open(process.env.REACT_APP_SERVER_API + "/file?fileName=" + res?.data?.data?.fileName)
        }
    }

    const deleteCatalog = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await axios.delete(process.env.REACT_APP_SERVER_API + `/catalogue?catalogueId=${id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })
                if (res.status === 200) {
                    getAllCatalog()
                }
                Swal.fire(
                    {
                        title: 'Deleted!',
                        text: 'Your catalog has been deleted.',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    }
                )
            }
        })
    }

    const markConfirmCatalog = async (id) => {
        const res = await axios.patch(process.env.REACT_APP_SERVER_API + `/catalogue/isreadonly?id=${id}`,
            {
                isReadOnly: true
            }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        })
        if (res.status === 200) {
            Swal.fire({
                title: 'Catalog Confirmed.',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000
            })
            getAllCatalog()
        }
    }

    return (
        <StyleRoot>
            <div>
                <Header />
                <div className="pt-32 bg-gray-200 min-h-screen">
                    <div className="mb-1">
                        <div className="font-extrabold text-2xl text-center"> CATALOG SECTION</div>
                        <div className="text-center">Creation Part Of Catalog</div>
                    </div>
                    <div className="sticky top-24 bg-gray-200 p-5 z-10">
                        <div className="w-full flex justify-end pr-6">
                            <button className='py-3 rounded-xl w-full md:w-40 bg-yellow-500 hover:bg-yellow-600 text-white' onClick={() => navigate("/addcatalog")}>Add Catalog</button>
                        </div>
                    </div>
                    <div className="p-6 overflow-auto">
                        <table className='w-full'>
                            <thead className='bg-gray-300 font-bold text-gray-600 uppercase leading-normal rounded-t-md'>
                                <th className="text-center"></th>
                                <th className="py-3 px-6 text-center w-28">Action</th>
                                <th className="py-3 px-6 text-center">Sr No.</th>
                                <th className="py-3 text-center">Catalog Name</th>
                                <th className="py-3 px-6 text-center">Download</th>
                                <th className="py-3 px-6 text-center">Download Excel</th>
                            </thead>
                            <tbody>
                                {
                                    allCatalog.map((catalog, index) => {
                                        return (
                                            <>
                                                <tr className="bg-white border border-gray-200 text-gray-600 uppercase border-b-2" key={index}>
                                                    <td className="text-center w-16 px-2 border-r">
                                                        <button
                                                            className='bg-gray-200 h-10 w-10 p-1.5 rounded-md cursor-pointer items-center text-center'
                                                            type="button"
                                                            onClick={() => {
                                                                const catalogs = allCatalog
                                                                catalogs[index]["expanded"] = !catalogs[index]["expanded"]
                                                                if (catalogs[index]["expanded"]) {
                                                                    getCatalogProducts(catalog._id)
                                                                }
                                                                setAllCatalog([
                                                                    ...catalogs
                                                                ])
                                                            }}>
                                                            {!catalog.expanded ?
                                                                <i className='fa fa-caret-right text-lg'></i>
                                                                :
                                                                <i className='fa fa-caret-down text-lg'></i>
                                                            }
                                                        </button>
                                                    </td>
                                                    <td className='px-6 border-r w-28'>
                                                        <div className='flex justify-center gap-2'>
                                                            {!catalog?.isReadOnly ?
                                                                <><button className="p-2 px-3 rounded-full bg-white text-yellow-500 font-medium text-xs leading-normal uppercase shadow-md hover:text-yellow-600  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out align-center flex hover:underline"
                                                                    onClick={() => {
                                                                        navigate("/addcatalog?id=" + catalog._id);
                                                                    }}>
                                                                    <i className='fas fa-pen-nib text-lg' title='Edit'></i>
                                                                </button><button className="p-2 px-3 rounded-full bg-white text-red-600 font-medium text-xs leading-normal uppercase shadow-md hover:text-yellow-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out align-center flex hover:underline"
                                                                    onClick={() => {
                                                                        deleteCatalog(catalog._id);
                                                                    }}>
                                                                        <i className='fas fa-trash-alt text-lg' title='Delete'></i>
                                                                    </button>
                                                                    <button className="p-2 px-3 rounded-full bg-white text-green-600 font-medium text-xs leading-normal uppercase shadow-md hover:text-yellow-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-600 active:shadow-lg transition duration-150 ease-in-out align-center flex hover:underline"
                                                                        onClick={() => {
                                                                            markConfirmCatalog(catalog._id)
                                                                        }}>
                                                                        <i className='far fa-check-circle text-lg' title='Mark Confirm'></i>
                                                                    </button></>
                                                                :
                                                                <span className='text-green-600 font-bold'>Confirmed</span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="px-6 text-center border-r">
                                                        <div className="px-6 text-center">
                                                            {index + 1}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 text-center border-r">{catalog.name}</td>
                                                    <td className="text-center border-r">
                                                        <img src={QrCode} title="QR Code" className="w-12 m-auto cursor-pointer" alt="QR Code" onClick={() => getQrCode(catalog)} />
                                                    </td>
                                                    <td className="px-6 text-center">
                                                        <ExportExcel catalog={catalog} />
                                                    </td>
                                                </tr>
                                                {catalog.expanded ?
                                                    <tr>
                                                        <td className="py-4 px-5 overflow-y-auto bg-white shadow-lg" colSpan={6}>
                                                            <section className="text-gray-600 body-font">
                                                                <div className="flex flex-wrap">
                                                                    {catalogProduct?.map((product, index) => {
                                                                        return (
                                                                            <div className="p-2 lg:w-1/4 md:w-1/2 w-full" key={index}>
                                                                                <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                                                    <div><img alt={index + 1} className="w-16 h-16 bg-gray-100 object-contain object-center flex-shrink-0 rounded-full mr-4" src={product.image[0]} />
                                                                                    </div>
                                                                                    <div className="flex-grow">
                                                                                        <h2 className="text-gray-600 text-lg font-medium">{product.name}</h2>
                                                                                        <p className="text-gray-500">{catalog.name}</p>
                                                                                    </div>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            getQRProducts(product);
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            alt="QrCode"
                                                                                            className="w-12 h-12 bg-gray-100 cursor-pointer object-cover object-center flex-shrink-0 mr-4"
                                                                                            src={QrCode}
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </section>
                                                        </td>
                                                    </tr>
                                                    : null}
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer />
            </div>
        </StyleRoot>
    )
}
