import React, { useEffect, useState, useRef } from 'react'
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { useNavigate } from 'react-router-dom';
import { StyleRoot } from "radium";
import axios from '../config/api';
import Swal from 'sweetalert2';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function SearchCatalog() {
    const [prefix, setPrefix] = useState("");
    const [input1, setInput1] = useState("");

    const getCatalog = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/catalogue?catalogueName=${input1}`,
            {
                headers: {
                    authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                },
            }
        );
        if (res.status === 200) {
            window.open(`/catalogdetails?catalogueNumber=${res?.data?.data?.[0].number}`, "_blank")
        }
        else {
            Swal.fire({
                title: 'Catalog Not Found',
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000
            })
        }
    };

    const getCatalogProduct = async () => {
        const res = await axios.get(
            process.env.REACT_APP_SERVER_API + `/catalogue-product?CProductName=${input1}`,
            {
                headers: {
                    authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                },
            }
        );
        if (res.status === 200) {
            window.open(`/catalogdetails?CProductNumber=${res?.data?.data?.[0].number}`, "_blank")
        }
        else {
            Swal.fire({
                title: 'Product Not Found',
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000
            })
        }
    };

    useEffect(() => {
        setPrefix("")
        if (input1.length && prefix != "") {
            if (prefix == "CL") {
                getCatalog()
            }
            if (prefix == "CP") {
                getCatalogProduct()
            }
        }
    }, [prefix])

    const onChangeHandler = (val) => {
        if (val.id == "Input1") {
            setInput1(val.value.toUpperCase())
        }
        if (val.id == "Prefix") {
            setPrefix(val.value)
        }
    }

    return (
        <StyleRoot>
            <div>
                <Header />
                <div className="min-h-full">
                    <div className="relative bg-no-repeat bg-cover min-h-screen">
                        <ProgressiveImage src="https://wall-empire.s3.ap-south-1.amazonaws.com/home/Ads/Ad+Web+5.jpeg">
                            {(src) => (
                                <img src={src} className=" min-h-screen w-full" />
                            )}
                        </ProgressiveImage>
                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-fixed flex flex-col justify-center" style={{ backgroundColor: "rgba(128,128,128,0.8)" }}>
                            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                                <div className="fixed bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                <div className="inset-0 z-10 overflow-y-auto">
                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                        <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all">
                                            <div className='p-5'>
                                                <div className='text-center cursor-default uppercase block w-full px-4 py-3 text-3xl font-normal text-gray-600 md:w-full lg:w-2/3 m-auto bg-white bg-clip-padding rounded transition ease-in-out'>
                                                    Enter Catalog Number or Product Number
                                                </div>
                                                <div className="grid mt-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full md:w-full lg:w-2/3 m-auto">
                                                    <div className='col-span-3'>
                                                        <input
                                                            type="text"
                                                            className="text-center uppercase block w-full px-4 py-3 text-3xl font-normal text-gray-700 bg-white bg-clip-padding rounded transition ease-in-out m-0 focus:text-gray-700 outline-none focus:bg-white focus:border-none focus:outline-none"
                                                            id="Input1" defaultValue={input1}
                                                            placeholder="SDA" onInput={(e) => onChangeHandler(e.target)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <select className="form-select px-4 py-4 form-select-lg mb-3 text-xl appearance-none block w-full font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 outline-none focus:bg-white focus:border-none focus:outline-none" aria-label=".form-select-lg example" id="Prefix" onChange={(e) => onChangeHandler(e.target)}>
                                                            <option selected className='text-center'>Select</option>
                                                            <option value="CL" className='text-center'>Catalog</option>
                                                            <option value="CP" className='text-center'>Catalog Product</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </StyleRoot>
    )
}