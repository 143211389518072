import { StyleRoot } from "radium";
import { useEffect, useState } from 'react';
import Step1_CreateCatalogue from './steps/Step1_CreateCatalog';
import Step2_AddProduct from './steps/Step2_AddProducts';
import Step3_ReviewCatalogue from './steps/Step3_ReviewCatalog';
import axios from '../../config/api';
import Header from '../Header';
import Footer from '../Footer';
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function AddCatalogue() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [steps, setSteps] = useState(1)

    const [catalogDetails, setCatalogDetails] = useState({
        name: "",
        image: "",
        specification: []
    })

    const [catalogueId, setCatalogueId] = useState("")

    const [products, setProducts] = useState([])

    const addCatalogAndProduct = async () => {
        const rescatalog = await axios.post(
            process.env.REACT_APP_SERVER_API + `/catalogue`,
            {
                catalogueName: catalogDetails?.name,
                catalogueImage: catalogDetails?.image,
                catalogueSpecification: catalogDetails.specification.map(item => item.text),
                perURL: process.env.REACT_APP_URL + "catalogdetails?catalogueNumber="
            },
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            },
        );

        if (rescatalog?.status === 200) {
            for (let i = 0; i < products.length; i++) {
                var productDetails = products[i]
                const name = productDetails.name;
                const image = productDetails.image;
                const image360 = productDetails.image360;
                const specification = productDetails.specification

                await axios.post(
                    process.env.REACT_APP_SERVER_API + `/catalogue-product`,
                    {
                        CProductName: name,
                        CProductImage: image,
                        CProductImage360: image360,
                        catalogueId: rescatalog?.data?.data?.catalogue?._id,
                        specification: specification,
                        perURL: process.env.REACT_APP_URL + "catalogdetails?CProductNumber=",
                    },
                    {
                        headers: {
                            authorization: localStorage.getItem('_token'),
                        },
                    },
                );
            }
            navigate("/catalog")
        }
    }

    const updateCatalogAndProduct = async () => {
        const updaterescatalog = await axios.patch(
            process.env.REACT_APP_SERVER_API + `/catalogue?id=` + catalogueId,
            {
                catalogueName: catalogDetails?.name,
                catalogueImage: catalogDetails?.image,
                catalogueSpecification: catalogDetails.specification.map(item => item.text),
                perURL: process.env.REACT_APP_URL + "catalogdetails?catalogueNumber="
            },
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            },
        );

        if(updaterescatalog?.status === 200) {
            for (let i = 0; i < products.length; i++) {
                var productDetails = products[i]
                const name = productDetails.name;
                const image = productDetails.image;
                const image360 = productDetails.image360;
                let specification = productDetails.specification

                Object.keys(specification).map(item => {
                    if(!catalogDetails.specification.map(item => item.text).includes(item)) {
                        delete specification[item]
                    }
                })

                await axios.patch(
                    process.env.REACT_APP_SERVER_API + `/catalogue-product?id=` + productDetails?._id,
                    {
                        CProductName: name,
                        CProductImage: image,
                        CProductImage360: image360,
                        specification: specification,
                        perURL: process.env.REACT_APP_URL + "catalogdetails?CProductNumber=",
                    },
                    {
                        headers: {
                            authorization: localStorage.getItem('_token'),
                        },
                    },
                );
            }
            navigate("/catalog")
        }
    }

    const fetchCatalogue = async (catalogueid) => {
        const rescatalog = await axios.get(
            process.env.REACT_APP_SERVER_API + `/catalogue?catalogueId=` + catalogueid,
            {
                headers: {
                    authorization: localStorage.getItem('_token'),
                },
            },
        );

        if(rescatalog.status === 200) {
            const catalogResult = rescatalog.data.data[0]
            setCatalogDetails({
                name: catalogResult.name,
                image: catalogResult.image,
                specification: catalogResult.specification.map(item => {
                    return {
                        id: item,
                        text: item
                    }
                })
            })

            setProducts([...catalogResult.catalogueProducts.map(item => {
                return {
                    ...item,
                    name: item.name,
                    image: item.image,
                    image360: item.image360,
                    specification: item.specification
                }
            })])
        }
    }

    useEffect(() => {
        if(searchParams.get('id')) {
            const catalogueid = searchParams.get('id')
            setCatalogueId(catalogueid)
            fetchCatalogue(catalogueid);
        }
    }, [])

    return (
        <StyleRoot>
            <div>
                <Header />
                <div className="pt-32 min-h-screen">
                    <div className='bg-white w-full'>
                        {
                            steps === 1 ?
                                // eslint-disable-next-line react/jsx-pascal-case
                                <Step1_CreateCatalogue
                                    catalogDetails={catalogDetails}
                                    setCatalogDetails={setCatalogDetails}
                                    setProducts={setProducts}
                                    hasNext={(done) => {
                                        if (done) {
                                            setSteps(2)
                                        }
                                    }}
                                />
                                : steps === 2 ?
                                    // eslint-disable-next-line react/jsx-pascal-case
                                    <Step2_AddProduct
                                        catalog={catalogDetails}
                                        products={products}
                                        setProducts={setProducts}
                                        hasPrevious={() => {
                                            setSteps(1)
                                        }}
                                        hasNext={(done) => {
                                            if (done === true) {
                                                setSteps(3)
                                            }
                                        }}
                                    />
                                    : (
                                        // eslint-disable-next-line react/jsx-pascal-case
                                        <Step3_ReviewCatalogue
                                            catalogueId={catalogueId}
                                            catalog={catalogDetails}
                                            products={products}
                                            hasPrevious={() => {
                                                setSteps(2)
                                            }}
                                            hasNext={(done) => {
                                                if (done === true) {
                                                    if(catalogueId === "") {
                                                        addCatalogAndProduct()
                                                    } else {
                                                        updateCatalogAndProduct()
                                                    }
                                                }
                                            }}
                                        />
                                    )
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </StyleRoot >
    )
}
