import Footer from "../components/Footer";
import Header from "../components/Header";
import { StyleRoot } from "radium";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import moment from "moment/moment";

export default function UserInquiry() {
    const [inquiries, setInquiries] = useState([]);
    const navigate = useNavigate()
    let userData = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('_token');

    const getAllInquiries = async () => {
        if (userData == null || !userData?.user?.isEnquiry) {
            navigate('/')
        }
        else {
            let setInquiriesRes;
            if (userData?.user?.role?.toLowerCase() === "admin") {
                setInquiriesRes = await axios.get(
                    process.env.REACT_APP_SERVER_API + `/enquiry?page=0&limit=10000`,
                    {
                        headers: {
                            authorization: token.toString()
                        },
                    }
                );
                if (setInquiriesRes.status === 200) {
                    setInquiries(setInquiriesRes.data.data.enquiry);
                }
            }
            else {
                setInquiriesRes = await axios.get(
                    process.env.REACT_APP_SERVER_API + `/enquiry?user_id=${userData?.user?._id}`,
                    {
                        headers: {
                            authorization: "Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl",
                        },
                    }
                );
                if (setInquiriesRes.status === 200) {
                    setInquiries(setInquiriesRes.data.data.enquiry);
                }
            }
        }
    };

    useEffect(() => {
        pageProcess();
    }, []);

    const pageProcess = async () => {
        setInquiries([]);
        await getAllInquiries();
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await axios.delete(process.env.REACT_APP_SERVER_API + `/enquiry/product?id=${id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })

                if (res.status === 200) {
                    getAllInquiries()
                }

                Swal.fire(
                    {
                        title: 'Deleted',
                        text: "Your record has been deleted.",
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1000
                    }
                )
            }
        })
    }

    const customStyles = {
        header: {
            style: {
                minHeight: 0
            }
        },
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                backgroundColor: 'rgb(230, 244, 244)',
                color: '#202124',
                fontSize: '15px',
                fontWeight: 'bold',
                textTransform: 'capitalize'
            },
        },
        rows: {
            style: {
                fontSize: '15px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
    };

    const columns = [
        {
            name: "Name",
            selector: (row) => row?.name,
        },
        {
            name: "Date",
            selector: (row) => moment(row?.createAt).format("DD-MM-yyyy"),
        },
        {
            name: "Email",
            selector: (row) => row?.email,
        },
        {
            name: "Phone No",
            selector: (row) => row?.contact_number,
        },
        {
            name: "Action",
            width: "5%",
            selector: (row) =>
                <div>
                    <div className="flex item-center justify-center">
                        <div className="transform hover:text-yellow-400 hover:scale-110">
                            <button type="button" className="inline-block rounded-full text-black hover:text-yellow-400 leading-normal uppercase shadow-md transform duration-200 hover:scale-105 hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg " onClick={() => handleDelete(row._id)}>
                                <i className="fas fa-trash-alt text-base"></i>
                            </button>
                        </div>
                    </div>
                </div>
        }
    ];

    return (
        <StyleRoot>
            <Header />
            <div className="max-w-full mx-auto min-h-screen pt-32 px-6">
                <div className="mb-1 pb-6">
                    <div className="font-extrabold text-2xl text-center">User Inquiries</div>
                </div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden">
                                <DataTable
                                    title={""}
                                    className="touch-auto"
                                    columns={columns}
                                    data={inquiries}
                                    fixedHeader={true}
                                    defaultSortAsc={true}
                                    pagination
                                    striped={true}
                                    responsive={true}
                                    persistTableHead={true}
                                    highlightOnHover
                                    customStyles={customStyles}
                                    onRowClicked={(e) => {
                                        Swal.fire({
                                            title: "<strong>Name : " + e?.name + "</strong>",
                                            html: '<b><u>Description</u></b><br />' + e?.enquiry_details,
                                            showCancelButton: false,
                                            padding:"5px",
                                            showCloseButton: true,
                                            confirmButtonColor: "#f3c811"
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </StyleRoot>
    );
}