import { useEffect, useState, useRef } from 'react'
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import axios from '../config/api';
import { useNavigate } from 'react-router-dom';
import S3 from 'aws-sdk/clients/s3'
import Modal from '@material-ui/core/Modal';
import ImageUploading from 'react-images-uploading';
import uploadGif from '../resources/images/cloud-upload.gif';
import ProgressiveImage from "react-progressive-graceful-image";
import { X } from 'heroicons-react';
import { randomBytes } from 'crypto';
import Radium, { StyleRoot } from "radium";
import { fadeIn } from "react-animations";
import Swal from 'sweetalert2';

const styles = {
    fadeIn: {
        animation: "x 1s",
        animationName: Radium.keyframes(fadeIn, "fadeIn"),
    },
};

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
];

export default function PremiumProducts({ history }) {
    const ref = useRef();
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    })

    const navigate = useNavigate();
    const [product, setProduct] = useState([]);
    const [isDataNull, setIsDataNull] = useState(true);
    const [itemOffset] = useState(20)
    const [pageCount] = useState(0);

    const [open, setOpen] = useState(false);
    const [postImage, setpostImage] = useState(['', '', '', '']);
    const [checkImage, setcheckImage] = useState([false, false, false, false]);
    const [nextCheckImage, setnextCheckImage] = useState([false, false, false, false, false]);
    const [subCategoryId, setSubCategoryId] = useState();
    const [category, setCategory] = useState([]);
    const [subcategory, setSubCategory] = useState([]);
    const [checkedState, setCheckedState] = useState(
        new Array(names.length).fill(false)
    );
    const [scrollValue, setScrollValue] = useState(0);
    const [mobileScreenStatus, setMobileScreenStatus] = useState(false)
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [hasMoreRecords, setHasMoreRecords] = useState(false)
    const [productNotFound, setProductNotFound] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [pid, setPid] = useState('')
    const [postName, setPostName] = useState('')
    const [description, setDescription] = useState('')
    const [productStatus, setProductStatus] = useState('non-premium')
    const [mainCategory, setMainCategory] = useState('')
    const [productSubCategory, setProductSubCategory] = useState('')
    const [width, setWidth] = useState('')
    const [composition, setComposition] = useState('')
    const [repatSize, setRepatSize] = useState('')
    const [weigthGsm, setWeigthGsm] = useState('')

    const [userData] = useState(JSON.parse(localStorage.getItem('user')));

    useEffect(() => {
        getProduct()
    }, [pageCount])

    const getProduct = async () => {
        if (userData == null) {
            navigate('/')
        } else {
            const response = await axios.get(process.env.REACT_APP_SERVER_API + `/product?productStatus=premium&limit=${itemOffset}&page=${pageCount}`, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (response.data.data?.post == null) {
                setIsDataNull(true)
            }
            if (response.status == 200) {
                if (response?.data?.data?.post) {
                    setProduct(response.data.data.post)
                    setCount(response.data.data.count)
                    setIsDataNull(false)
                }
            }
        }
    }

    const getPaginatedProduct = async () => {
        if (userData == null) {
            navigate('/')
        } else {
            const resProduct = await axios.get(process.env.REACT_APP_SERVER_API + '/product?productStatus=premium&page=' + page + '&limit=' + itemOffset, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (resProduct.status === 200) {
                setProduct([...product, ...resProduct.data.data.post])
            }
        }
    }

    const getSearchedProduct = async () => {
        if (searchQuery.length > 3) {
            const resProduct = await axios.get(process.env.REACT_APP_SERVER_API + '/product?productStatus=premium&postName=' + searchQuery, {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            })
            if (resProduct.status === 200) {
                setProduct(resProduct?.data?.data?.post)
                if (resProduct.data.data?.post == null)
                    setProductNotFound(true)
            }
        }
        else {
            getProduct()
        }
    }

    useEffect(() => {
        getSearchedProduct()
    }, [searchQuery])

    useEffect(() => {
        if (windowSize.innerWidth < 1008 || windowSize.innerHeight < 900) {
            setMobileScreenStatus(true)
        } else {
            setMobileScreenStatus(false)
        }
    }, [windowSize])

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const [scrollClientHeight, setScrollClientHeight] = useState()
    const onScroll = (e) => {
        setScrollValue(e.target.documentElement.scrollTop);
        setScrollClientHeight(e.target.documentElement.scrollHeight - e.target.documentElement.clientHeight)
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        if (mobileScreenStatus) {
            if ((scrollValue * 100) / scrollClientHeight > 95) {
                if (product?.length <= count) {
                    var start = page
                    start = start + 1
                    setPage(start)
                    setHasMoreRecords(true)
                    getPaginatedProduct()
                }
                else
                    setHasMoreRecords(false)
            }
        }
        else if (scrollValue == scrollClientHeight) {
            if (product?.length <= count) {
                var start = page
                start = start + 1
                setPage(start)
                setHasMoreRecords(true)
                getPaginatedProduct()
            }
            else
                setHasMoreRecords(false)
        }
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollValue]);

    const getCategory = () => {
        if (userData == null) {
            navigate('/')
        } else {
            axios.get(process.env.REACT_APP_SERVER_API + '/category', {
                headers: {
                    authorization: localStorage.getItem('_token')
                }
            }).then(function (response) {
                setCategory(response.data.data)
            }).catch(function (error) {
            });
        }
    }

    const checkSubCategory = async (event) => {
        var categoryId = await event.target && event.target?.value ? event?.target?.value : event
        setMainCategory(categoryId)
        if (categoryId != null && categoryId != '') {
            let id = categoryId
            if (userData == null) {
                navigate('/')
            } else {
                const response = await axios.get(process.env.REACT_APP_SERVER_API + '/category?category_id=' + id, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })
                let data = [], index = 0;

                if (response.data.data) {
                    response.data.data?.forEach((item) => {
                        item.section?.forEach((subItem) => {
                            if (subItem.items.length > 0) {
                                subItem.items?.forEach((subCate) => {
                                    data[index] = subCate
                                    index++
                                })
                            }
                        })
                    })
                    setSubCategory(data)
                }
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setpostImage(['', '', '', '']);
        setcheckImage([false, false, false, false]);
        setnextCheckImage([false, false, false, false, false]);
    };

    const handleSubCategoryId = (e) => {
        setSubCategoryId(e.target.value)
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    }

    const handlepostImage = (imageList, index) => {
        var status = checkImage.map((item, i) =>
            i == (index - 1) ? true : item
        )
        setcheckImage(status)
        if (postImage[index - 1]) {
            setpostImage(postImage.map((item, i) => i == index - 1 ? '' : item));

            imageDelete(postImage[index - 1].replace("https://wall-empire.s3.ap-south-1.amazonaws.com/", ""))
            imageUpload(imageList[0], (url) => {
                var image = postImage.map((item, i) =>
                    i == (index - 1) ? url : item
                )
                setpostImage(image)

                status = nextCheckImage.map((item, i) =>
                    i == index ? true : item
                )
                setnextCheckImage(status)
            })
        } else {
            imageUpload(imageList[0], (url) => {
                var image = postImage.map((item, i) =>
                    i == (index - 1) ? url : item
                )
                setpostImage(image)

                status = nextCheckImage.map((item, i) =>
                    i == index ? true : item
                )
                setnextCheckImage(status)
            })
        }
    };

    const handleDelete = async (product) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                imageDelete(product.postImage1.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))
                imageDelete(product.postImage2.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))
                imageDelete(product.postImage3.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))
                imageDelete(product.postImage4.replace('https://wall-empire.s3.ap-south-1.amazonaws.com/', ''))

                const productRes = await axios.delete(process.env.REACT_APP_SERVER_API + `/product?product_id=${product._id}`, {
                    headers: {
                        authorization: localStorage.getItem('_token')
                    }
                })

                if (productRes.status === 200) {
                    getProduct()
                }

                Swal.fire(
                    {
                        title: 'Deleted',
                        text: "Your file has been deleted.",
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1000
                    }
                )
            }
        })
    }

    useEffect(() => {
        getCategory()
    }, [mainCategory])

    const updatePopup = async (item) => {
        const use = []
        names.map((enduse, index) => {
            if (item.end_use.includes(enduse)) {
                use.push(true)
            } else {
                use.push(false)
            }
        });
        setCheckedState([...use])
        await checkSubCategory(item.category._id)
        setProductSubCategory(item.sub_category._id)
        setMainCategory(item.category._id)
        setPid(item._id)
        setPostName(item.postName)
        setDescription(item.description)
        setProductStatus(item.productStatus)
        setWidth(item.details.width)
        setComposition(item.details.composition)
        setRepatSize(item.details.repeat_size)
        setWeigthGsm(item.details.weight_gsm)
        setpostImage([item.postImage1, item.postImage2, item.postImage3, item.postImage4])
        setnextCheckImage([true, true, true, true, true])
        setcheckImage([true, true, true, true])
        setOpen(true)
    }

    const updateProduct = async () => {
        var end_use = []
        checkedState.map((item, index) => {
            if (item == true) {
                end_use.push(names[index]);
            }
        });
        await axios.put(process.env.REACT_APP_SERVER_API + '/product', {
            "product_id": pid,
            "postName": postName,
            "description": description,
            "productStatus": productStatus,
            "postImage1": postImage[0],
            "postImage2": postImage[1],
            "postImage3": postImage[2],
            "postImage4": postImage[3],
            "width": width,
            category_id: mainCategory,
            sub_category_id: subCategoryId,
            "composition": composition,
            "repeat_size": repatSize,
            "weight_gsm": weigthGsm,
            end_use: end_use
        }, {
            headers: {
                authorization: localStorage.getItem('_token')
            }
        })
        await getSearchedProduct()
        setOpen(false)
    }

    const imageDelete = (imageFile) => {
        s3.deleteObject({
            Bucket: bucketName,
            Key: imageFile
        }, function (err, data) {
            return data
        })
    }

    const imageUpload = async (imageFile, callback) => {
        const rawBytes = await randomBytes(16)
        const imageName = rawBytes.toString('hex')

        const uploadURL = await s3.getSignedUrlPromise('putObject', {
            Bucket: bucketName,
            Key: "Products/" + imageName,
            Expires: 60
        })

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: imageFile.file
        })

        const imageUrl = uploadURL.split('?')[0]
        callback(imageUrl)
    };

    return (
        <StyleRoot>
            <div ref={ref}>
                <Header />
                <Modal open={open} onClose={handleClose} className="overflow-y-scroll ml-5">
                    <div className='bg-white mx-auto m-10 p-10'>
                        <div className="w-full flex justify-between bg-white p-5 rounded-lg lg:rounded-l-none">
                            <h1 className="font-weight-bold text-center text-3xl">Product Uploading</h1>
                            <X
                                onClick={() => handleClose()}
                                className="cursor-pointer border-2 top-8 right-6 rounded-full border-white"
                            />
                        </div>
                        <div className="grid grid-cols-3 gap-4 p-5 w-full bg-white rounded-lg lg:rounded-l-none">
                            <form id="hook-form" className="col-span-2">
                                <div className="w-full">
                                    <input name="name" defaultValue={postName} className="w-full text-lg py-2 mb-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" type="text" placeholder="Enter Product Name" onChange={(e) => setPostName(e.target.value)} />
                                </div>
                                <div>
                                    <textarea name="description" defaultValue={description} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" col="10" row="10" placeholder="Enter Product Description" onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                                <div>
                                    <select name="type" defaultValue={productStatus} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" onChange={(e) => setProductStatus(e.target.value)}>
                                        <option value="non-premium">Non-premium</option>
                                        <option value="premium">Premium</option>
                                    </select>
                                </div>
                                <div>
                                    <select name="category" defaultValue={mainCategory} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" onChange={(e) => checkSubCategory(e)}>
                                        <option value="" selected>Please Select Category</option>
                                        {category?.map((item, index) => {
                                            return (<option key={index} value={item._id}>{item.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <select name="subcategory" defaultValue={productSubCategory} onChange={handleSubCategoryId} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500">
                                        <option value="" selected>Please Select Sub-Category</option>
                                        {subcategory?.map((item, index) => {
                                            return (<option key={index} value={item._id}>{item.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <input name="width" defaultValue={width} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" type="text" placeholder="Enter width" onChange={(e) => setWidth(e.target.value)} />
                                </div>

                                <div>
                                    <input name="composition" defaultValue={composition} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" type="text" placeholder="Enter composition" onChange={(e) => setComposition(e.target.value)} />
                                </div>

                                <div>
                                    <input name="size" defaultValue={repatSize} className="mb-2 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" type="text" placeholder="Enter repeat size" onChange={(e) => setRepatSize(e.target.value)} />
                                </div>

                                <div>
                                    <input name="gsm" defaultValue={weigthGsm} className="mb-6 w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-yellow-500" type="text" placeholder="Enter weight gsm" onChange={(e) => setWeigthGsm(e.target.value)} />
                                </div>

                                <div className='grid grid-cols-3 gap-4 '>
                                    {names.map((name, index) => (
                                        <div>
                                            <input type="checkbox" name="end_use[]" onChange={() => handleOnChange(index)} id={`custom-checkbox-${index}`} checked={checkedState[index]} value={name} /> {name}
                                        </div>
                                    ))}
                                </div>
                            </form>
                            <div>
                                <div className="w-full mb-5">
                                    <ImageUploading
                                        onChange={(e) => handlepostImage(e, 1)}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                        }) => {
                                            return (
                                                checkImage[0] ?
                                                    postImage[0] != '' ? (
                                                        <div className="w-full h-32" onClick={onImageUpload}>
                                                            <ProgressiveImage src={postImage[0]}>
                                                                {(src) => (
                                                                    <img className='object-contain w-full h-full' src={src} />
                                                                )}
                                                            </ProgressiveImage>
                                                        </div>
                                                    ) : <div className="w-full h-32">
                                                        <img className='object-contain w-full h-full' src={uploadGif} />
                                                    </div> : (<button
                                                        className="w-full h-32 border-2 border-dashed border-yellow-500 text-blue-500"
                                                        onClick={onImageUpload}
                                                    >
                                                        Click or Drop here
                                                    </button>)
                                            )
                                        }}
                                    </ImageUploading>
                                </div>

                                {nextCheckImage[1] == true ? <div className="w-full mb-5">
                                    <ImageUploading
                                        onChange={(e) => handlepostImage(e, 2)}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                        }) => (
                                            checkImage[1] ?
                                                postImage[1] != '' ?
                                                    (
                                                        <div className="w-full h-32" onClick={onImageUpload}>
                                                            <ProgressiveImage src={postImage[1]}>
                                                                {(src) => (
                                                                    <img className='object-contain w-full h-full' src={src} />
                                                                )}
                                                            </ProgressiveImage>
                                                        </div>
                                                    ) : <div className="w-full h-32">
                                                        <img className='object-contain w-full h-full' src={uploadGif} />
                                                    </div> : (<button
                                                        className="w-full h-32 border-2 border-dashed border-yellow-500 text-blue-500"
                                                        onClick={onImageUpload}
                                                    >
                                                        Click or Drop here
                                                    </button>)
                                        )}
                                    </ImageUploading>
                                </div> : <div></div>}

                                {nextCheckImage[2] == true ? <div className="w-full mb-5">
                                    <ImageUploading
                                        onChange={(e) => handlepostImage(e, 3)}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                        }) => (
                                            checkImage[2] == true ?
                                                postImage[2] != '' ? (
                                                    <div className="w-full h-32" onClick={onImageUpload}>
                                                        <ProgressiveImage src={postImage[2]}>
                                                                {(src) => (
                                                                    <img className='object-contain w-full h-full' src={src} />
                                                                )}
                                                            </ProgressiveImage>
                                                    </div>
                                                ) : <div className="w-full h-32">
                                                    <img className='object-contain w-full h-full' src={uploadGif} />
                                                </div> : (<button
                                                    className="w-full h-32 border-2 border-dashed border-yellow-500 text-blue-500"
                                                    onClick={onImageUpload}
                                                >
                                                    Click or Drop here
                                                </button>)
                                        )}
                                    </ImageUploading>
                                </div> : <div></div>}

                                {nextCheckImage[3] == true ? <div className="w-full mb-5">
                                    <ImageUploading
                                        onChange={(e) => handlepostImage(e, 4)}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                        }) => (
                                            checkImage[3] == true ?
                                                postImage[3] != '' ? (
                                                    <div className="w-full h-32" onClick={onImageUpload}>
                                                        <ProgressiveImage src={postImage[3]}>
                                                                {(src) => (
                                                                    <img className='object-contain w-full h-full' src={src} />
                                                                )}
                                                            </ProgressiveImage>
                                                    </div>
                                                ) : <div className="w-full h-32">
                                                    <img className='object-contain w-full h-full' src={uploadGif} />
                                                </div> : (
                                                    <button
                                                        className="w-full h-32 border-2 border-dashed border-yellow-500 text-blue-500"
                                                        onClick={onImageUpload}
                                                    >
                                                        Click or Drop here
                                                    </button>)
                                        )}
                                    </ImageUploading>
                                </div> : <div></div>}
                                <div className="mt-10">
                                    <button form="hook-form" type='button' className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600 shadow-lg" onClick={(e) => updateProduct(e)}>
                                        Update Product
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="pl-4 pr-4 pt-32 bg-gray-200">
                    <div className="mb-1">
                        <div className="font-extrabold text-2xl text-center"> Premium Products</div>
                    </div>
                    <div className="sticky top-24 bg-gray-200 pt-4 z-10 grid grid-cols-1 md:grid-cols-2 gap-4 pl-5">
                        <div className="flex items-center w-full md:w-96 pr-6">
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                </div>
                                <input type="text" id="search" autoComplete='off' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" onChange={(e) => setSearchQuery(e.target.value)} />
                            </div>
                            <button type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-yellow-500 rounded-lg border border-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-400 dark:bg-blue-600 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"><svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={() => getSearchedProduct()}><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></button>
                        </div>
                    </div>
                    {isDataNull ?
                        <div className="text-center p-5 h-screen">
                            <div className="text-base"> No Premium products available</div>
                        </div>
                        :
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-5">
                            {product?.map((item, index) => {
                                return (
                                    <div key={index} className="rounded-xl shadow-2xl bg-gray-200 overflow-hidden divide-black relative">
                                        <div className="overflow-hidden">
                                            <div className='mt-2 mr-2 right-0 absolute'>
                                                <div>
                                                    <button type="button" className="inline-block bg-gray-800 rounded-full text-white hover:text-black hover:bg-white leading-normal uppercase shadow-md transform duration-200 hover:scale-105 hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-8 h-8 md:w-10 md:h-10 p-1 md:p-2.5" onClick={() => updatePopup(item)}>
                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" width="25px" height="25px"><path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z" /></svg>
                                                    </button>
                                                </div>
                                                <div className='mt-2'>
                                                    <button type="button" className="inline-block bg-gray-800 rounded-full text-white hover:text-black hover:bg-white leading-normal uppercase shadow-md transform duration-200 hover:scale-105 hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-8 h-8 md:w-10 md:h-10 p-1 md:p-2.5" onClick={() => handleDelete(item)}>
                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="22px" height="22px">    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" /></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <ProgressiveImage src={item.postImage1} placeholder={'tiny-image.jpg'}>
                                                {(src, loading) => (
                                                    loading ?
                                                        <div className="p-4 max-w-sm w-full mx-auto my-4">
                                                            <div className="animate-pulse flex space-x-4">
                                                                <div className="rounded-full bg-gray-300 h-12 w-12"></div>
                                                                <div className="flex-1 space-y-6 py-1">
                                                                    <div className="h-2 bg-gray-300 rounded"></div>
                                                                    <div className="space-y-3">
                                                                        <div className="grid grid-cols-3 gap-4">
                                                                            <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                                                                            <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                                                        </div>
                                                                        <div className="h-2 bg-gray-300 rounded"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <img
                                                            className={`image${loading ? " loading" : " loaded rounded-lg"}`}
                                                            style={styles.fadeIn}
                                                            src={src}
                                                            width="700"
                                                            height="465"
                                                        />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                        <div className="pl-2 overflow-hidden pt-2">
                                            <b>Name : </b>{item.postName}
                                        </div>
                                        <div className="pl-2 overflow-hidden pb-2">
                                            <b>Description : </b>{item.description}
                                        </div>
                                        <div className="divide-x divide-gray-400"></div>
                                        <div className="grid grid-cols-3 divide-x divide-gray-400 pb-2">
                                            <div className="pl-2 overflow-hidden">
                                                <div className="w-full flex justify-center font-semibold">
                                                    Status
                                                </div>
                                                <div className="w-full flex justify-center">
                                                    {item.productStatus}
                                                </div>
                                            </div>
                                            <div className="pl-2 overflow-hidden">
                                                <div className="w-full flex justify-center font-semibold">
                                                    Category
                                                </div>
                                                <div className="w-full flex justify-center">
                                                    {item.category.name}
                                                </div>
                                            </div>
                                            <div className="pl-2 overflow-hidden">
                                                <div className="w-full flex justify-center font-semibold">
                                                    Sub Category
                                                </div>
                                                <div className="w-full flex justify-center">
                                                    {item.sub_category.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {/* {isFetching && !isDataNull && (<div class="flex justify-center items-center">
                    <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>)} */}
                </div>
                {
                    productNotFound ?
                        <div className="text-center p-5 text-lg font-bold text-gray-400">
                            <h1>No product found</h1>
                        </div>
                        : null
                }
                {/* {
                        hasMoreRecords ?
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-yellow-300 mb-4" role="status">
                                </div>
                            </div>
                            : null
                    } */}
                <Footer />
            </div >
        </StyleRoot>
    )
}
